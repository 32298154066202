export default function TextInput({
	attributes,
	handleClick,
	className,
	childClassName,
}) {
	return (
		<button
			type='button'
			aria-label={`Редактировать поле "${attributes?.label}"`}
			onClick={handleClick}
			{...Object.entries(attributes).reduce((acc, [key, value]) => {
				acc[`data-${key}`] = value
				return acc
			}, {})}
			className={`flex flex-col rounded-xl border border-[#E2E2E2] px-4 py-2 text-left
				${className}`}
		>
			<span className='pointer-events-none text-xs text-black/60'>
				{attributes?.label}
			</span>
			<span
				className={`pointer-events-none line-clamp-1 w-full text-ellipsis text-base ${
					childClassName || 'text-black' }`}
			>
				{attributes?.value || 'Отсутствует'}
			</span>
		</button>
	)
}
