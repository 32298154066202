import { useState } from 'react'
import { Link } from 'react-router-dom'
import LoginForm from './form/login-form'
import RequestResetPasswordForm from './form/request-reset-password-form'

export default function AuthManager() {
	const [authState, setAuthState] = useState({
		login: true,
	})

	return (
		<div className='w-full xs:w-80 sm:h-[344px]'>
			{authState.register && (
				<>
					<div className='text-base'>
						Чтобы приступить к&nbsp;регистрации
						<br />
						выберите свою&nbsp;роль
					</div>
					<Link
						to='/auth/register/blogger'
						className='mt-6 block rounded-xl bg-white/10 p-4 text-base font-semibold
							text-white'
					>
						Я - блогер
					</Link>
					<Link
						to='/auth/register/business'
						className='mt-2 block rounded-xl bg-white/10 p-4 text-base font-semibold
							text-white'
					>
						Я - бизнес
					</Link>{' '}
				</>
			)}
			{authState.login && (
				<>
					<LoginForm className='w-full' />
					<button
						aria-label='Зарегистрироваться'
						type='button'
						onClick={() => {
							setAuthState({ register: true })
						}}
						to='/auth/login'
						className='mt-2 w-full rounded-xl bg-white/10 p-4 text-base font-semibold
							text-white'
					>
						Зарегистрироваться
					</button>
					<div className='flex items-center justify-center'>
						<button
							type='button'
							aria-label='Забыл пароль'
							onClick={() => {
								setAuthState({ resetPassword: true })
							}}
							className='decoration-skip-ink-none mt-3 block text-center text-sm underline
								underline-offset-4 opacity-40'
						>
							Забыл пароль
						</button>
					</div>
				</>
			)}
			{authState.resetPassword && (
				<>
					<div className='mb-4 text-center text-base text-white'>
						Введите свою почту,
						<br />
						чтобы&nbsp;сбросить пароль
					</div>
					<RequestResetPasswordForm className='w-full' />
					<div
						className='fixed left-0 top-0 size-full flex-col items-center justify-center
							bg-primary-500/50 text-center text-base text-white
							sm:bg-primary-500/90 sm:text-lg'
						id='submit-window'
						style={{ display: 'none' }}
					>
						<span>Письмо отправлено!</span>
						<span className='mt-3'>
							Перейдите по&nbsp;ссылке
							<br />
							в&nbsp;письме для&nbsp;сброса пароля!
						</span>
					</div>
				</>
			)}
			{!authState.login && (
				<button
					type='button'
					onClick={() => {
						setAuthState({ login: true })
					}}
					className='mt-2 w-full rounded-xl bg-white/10 p-4 text-base font-semibold
						text-white'
					aria-label='Отменить'
				>
					Отменить
				</button>
			)}
			<a
				target='_blank'
				href='https://flowork.ru/users'
				className='decoration-skip-ink-none mt-6 block text-center text-sm underline
					underline-offset-4 opacity-40 sm:mt-12'
				rel='noreferrer'
			>
				Пользовательское соглашение
			</a>
			<a
				target='_blank'
				href='https://flowork.ru/privacy'
				className='decoration-skip-ink-none mt-2 block text-center text-sm underline
					underline-offset-4 opacity-40'
				rel='noreferrer'
			>
				Политика конфиденциальности
			</a>
		</div>
	)
}
