import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useLoaderData, useParams } from 'react-router-dom'
import DealContent from '../components/deals/deal-content'
import MessageInput from '../components/deals/message-input'

export default function DealPage() {
	const { data: dealData } = useLoaderData() || { data: null }

	const user = useAuthUser()

	const { chatId } = useParams()

	return (
		<>
			<div
				className='scrollbar-hide mb-3 flex h-[calc(100%-40px-12px)] flex-col
					overflow-y-scroll pt-2'
			>
				<DealContent content={dealData} userId={user?.id} />
			</div>
			<MessageInput
				className='shrink-0 bg-white'
				chatId={chatId}
				userId={user?.id}
			/>
		</>
	)
}
