import useSignOut from 'react-auth-kit/hooks/useSignOut'
import { useNavigate } from 'react-router-dom'

export default function SignOutButton({ className }) {
	const signOut = useSignOut()
	const navigate = useNavigate()

	return (
		<button
			onClick={() => {
				signOut()
				navigate('/auth/login')
			}}
			type='button'
			aria-label='Выйти из аккаунта'
			className={className}
		>
			Выйти из аккаунта
		</button>
	)
}
