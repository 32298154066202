/* eslint-disable no-restricted-syntax */

import { useRef } from 'react'
import ReactInputMask from 'react-input-mask'
import Spinner from '../icons/spinner'
import useLoader from '../utils/use-loader'

export default function InputForm({
	data,
	userId,
	authHeader,
	revalidator,
	handleClose,
}) {
	const labelRef = useRef(null)
	const { btnRef, toggleLoader } = useLoader()

	const toggleError = isDisabled => {
		labelRef.current?.classList?.toggle(
			'input-error-dark',
			isDisabled
		)
	}

	const handleChange = event => {
		const { maxlength } = data

		if (maxlength && labelRef.current && btnRef.current) {
			toggleError(event.target.value.length >= Number(maxlength))
			btnRef.current.disabled = true
		} else {
			toggleError(false)
			btnRef.current.disabled = false
		}
	}

	const handleSubmit = async () => {
		toggleLoader(true)
		const reqData = {}
		const fieldNameArr = data.field?.split('.') || []
		const inputValue = labelRef.current?.querySelector('input')?.value

		if (fieldNameArr.length > 1 && data.initial) {
			const parentIndex = fieldNameArr.length - 1

			let initial = { ...data.initial }
			let current = reqData

			for (let i = 0; i < parentIndex; i += 1) {
				const key = fieldNameArr[i]
				current[key] = { ...initial[key] }
				current = current[key]
				if (current?.id) delete current.id
				initial = initial[key]
			}

			current[fieldNameArr[parentIndex]] = inputValue
		} else {
			reqData[data.field] = inputValue
		}

		const { id, ...reqBody } = reqData

		if (data.type === 'address') {
			try {
				const coordsReq = await fetch(
					'https://server.flowork.ru/api/address',
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json',
						},
						body: JSON.stringify({
							address: inputValue,
						}),
					}
				)

				const coordsRes = await coordsReq.json()
				reqBody.profile.coords = coordsRes.join(', ')
			} catch (error) {
				toggleError(true)
				toggleLoader(false)
				return
			}
		}

		fetch(`https://api.flowork.ru/api/users/${userId}`, {
			method: 'PUT',
			headers: {
				'Content-type': 'application/json',
				Authorization: authHeader,
			},
			body: JSON.stringify(reqBody),
		})
			.then(res => {
				toggleLoader(false)
				if (res.status !== 200) {
					throw new Error('status error')
				}
				revalidator.revalidate()
				handleClose()
			})
			.catch(() => {
				toggleError(true)
				toggleLoader(false)
			})
	}

	const renderInput = () => {
		switch (data.type) {
			case 'phone':
				return (
					<ReactInputMask
						mask='8 999 999 99 99'
						alwaysShowMask={false}
						defaultValue={data.value || 'Неизвестное значение'}
						maskChar=''
						name={data.field}
						id={data.field}
						className='appearance-none bg-transparent text-base text-black outline-none
							transition-colors'
						type='tel'
					/>
				)
			default:
				return (
					<input
						type='text'
						name={data.field}
						id={data.field}
						onChange={handleChange}
						className='appearance-none bg-transparent text-base text-black outline-none
							transition-colors'
						defaultValue={data.value || 'Неизвестное значение'}
					/>
				)
		}
	}

	return (
		<>
			<div className='mb-4 text-center text-xl font-semibold text-primary-500'>
				Редактировать
			</div>
			<label
				className='flex flex-col rounded-xl border border-[#E2E2E2] px-4 py-2 text-left
					transition-colors'
				htmlFor={data.field}
				ref={labelRef}
			>
				<span className='text-xs text-black/60'>
					{data.label || 'Неизвестное поле'}
				</span>
				{renderInput()}
			</label>
			<button
				aria-label='Сохранить'
				type='button'
				ref={btnRef}
				onClick={handleSubmit}
				className='mt-2 flex w-full flex-row items-center justify-center rounded-xl
					bg-primary-500 px-4 py-3.5 text-base font-semibold text-white
					*:pointer-events-none'
			>
				<span>Сохранить</span>
				<Spinner
					className='size-6 rounded-full fill-gray-400 text-white'
					style={{ display: 'none' }}
				/>
			</button>
			<button
				type='button'
				onClick={handleClose}
				aria-label='Закрыть'
				className='mt-2 flex items-center justify-center rounded-xl bg-gray-200 px-4
					py-3.5 text-base font-semibold text-black'
			>
				Отменить
			</button>
		</>
	)
}
