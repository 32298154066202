import { useLoaderData, useRevalidator } from 'react-router-dom'
import { useEffect } from 'react'
import AvatarInput from '../components/profile/avatar-input'
import TextInput from '../components/profile/text-input'
import { useSetInputModalContext } from './profile-layout'
import TextInputDisabled from '../components/profile/text-input-disabled'
import SignOutButton from '../components/profile/sign-out-button'
import ContentWrapper from '../components/content-wrapper'

export default function ProfilePage() {
	const userData = useLoaderData()
	const revalidator = useRevalidator()
	const setInputModal = useSetInputModalContext()

	const handleClick = event => {
		setInputModal({ ...event.target.dataset, initial: userData })
	}

	const isBusiness = userData.profile.type === 'business'
	const profileType = isBusiness ? 'бизнеса' : 'блогера'

	useEffect(() => {
		const pageIndicator = document.getElementById('page-indicator')

		if (pageIndicator)
			pageIndicator.textContent = `Профиль ${profileType}`
	}, [profileType])

	return (
		<ContentWrapper>
			<h1 className='mb-4 hidden text-2xl font-semibold text-primary-500 lg:block'>
				Профиль {profileType}
			</h1>
			<AvatarInput
				image={userData.avatar}
				revalidator={revalidator}
			/>
			<TextInput
				attributes={{
					value: userData.profile.name,
					field: 'profile.name',
					label: 'Имя',
					modal: 'input',
				}}
				handleClick={handleClick}
				className='mt-2'
			/>
			<TextInput
				attributes={{
					value: userData.profile.surname,
					field: 'profile.surname',
					label: 'Фамилия',
					modal: 'input',
				}}
				handleClick={handleClick}
				className='mt-2'
			/>
			<TextInputDisabled
				attributes={{
					value: userData.email,
					label: 'Почта',
				}}
				className='mt-2'
			/>
			<TextInput
				attributes={{
					value: userData.profile.phone,
					field: 'profile.phone',
					label: 'Телефон',
					type: 'phone',
					modal: 'input',
				}}
				handleClick={handleClick}
				className='mt-2'
			/>
			<TextInput
				attributes={{
					value: userData.profile.description,
					field: 'profile.description',
					label: 'Описание',
					maxlength: 160,
					modal: 'input',
				}}
				handleClick={handleClick}
				className='mt-2'
			/>
			{userData.socialLinks?.map(item => (
				<TextInput
					key={item.link + item.label}
					attributes={{
						value: item.link,
						label: `Ссылка на ${item.label}`,
						modal: 'warning',
					}}
					className='mt-2'
					childClassName='text-black/60'
					handleClick={handleClick}
				/>
			))}
			{isBusiness && (
				<TextInput
					attributes={{
						value: userData.profile.address,
						field: 'profile.address',
						label: 'Адрес',
						modal: 'input',
						type: 'address',
					}}
					handleClick={handleClick}
					className='mt-2'
				/>
			)}
			<button
				type='button'
				aria-label='Добавить соц. сеть'
				data-modal='social'
				onClick={handleClick}
				className='mt-2 rounded-xl bg-primary-500/20 px-4 py-3.5 text-center
					font-semibold text-primary-500'
			>
				Добавить ссылку на соц. сеть
			</button>
			<SignOutButton className='mt-2 rounded-xl bg-gray-200/50 px-4 py-3.5 font-semibold text-black' />
			<a
				target='_blank'
				href='https://flowork.ru/users'
				className='decoration-skip-ink-none mt-2 text-base font-semibold text-primary-500
					underline decoration-2 underline-offset-[6px]'
				rel='noreferrer'
			>
				Пользовательское соглашение
			</a>
			<a
				target='_blank'
				href='https://flowork.ru/privacy'
				className='decoration-skip-ink-none mt-2 text-base font-semibold text-primary-500
					underline decoration-2 underline-offset-[6px]'
				rel='noreferrer'
			>
				Политика конфиденциальности
			</a>
			<button
				type='button'
				onClick={() => {
					setInputModal({ modal: 'delete' })
				}}
				aria-label='Удалить аккаунт'
				className='mt-4 rounded-xl bg-gray-200/50 px-4 py-3.5 font-semibold text-black'
			>
				Удалить аккаунт
			</button>
		</ContentWrapper>
	)
}
