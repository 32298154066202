export default function Error() {
	return (
		<section
			className='flex h-dvh min-h-fit flex-col items-center justify-center bg-white p-4
				text-center text-black lg:p-6 xl:p-7'
		>
			<h1 className='text-xl'>Произошла ошибка</h1>
			<p className='mt-1 opacity-60 md:w-2/5 md:min-w-96 xl:w-1/3'>
				Пожалуйста, проверьте соединение с&nbsp;интернетом
				и&nbsp;попробуйте снова. При&nbsp;повторе ошибки
				-&nbsp;обратитесь в&nbsp;службу поддержки.
			</p>
			<button
				aria-label='Перезагрузить страницу'
				type='button'
				className='mt-6 rounded-xl bg-primary-500 px-4 py-3.5 text-base font-semibold
					text-white'
				onClick={() => {
					window.location.reload()
				}}
			>
				Обновить
			</button>
		</section>
	)
}
