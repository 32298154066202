import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import { useNavigate } from 'react-router-dom'

export default function MapComponent({
	items,
	user = null,
	className,
}) {
	const navigate = useNavigate()

	return (
		<div className={`size-full ${className}`}>
			<YMaps preload>
				<Map
					defaultState={{
						center: [55.751574, 37.573856],
						zoom: 11,
					}}
					state={{
						center: [55.751574, 37.573856],
						zoom: 11,
					}}
					width='100%'
					height='100%'
				>
					{items?.map(({ attributes: item }) => {
						const userInfo = item.user?.data?.attributes || user

						const avatar =
							userInfo.avatar?.data?.attributes?.url ||
							userInfo.avatar?.url

						const coords = userInfo.profile?.coords
						return (
							<Placemark
								key={item.createdAt}
								geometry={coords?.split(', ')}
								onClick={() => {
									navigate(`/feed/${item.user.data.id}`)
								}}
								options={{
									iconLayout: 'default#image',
									iconImageHref: avatar
										? `https://api.flowork.ru${avatar}?format=webp&w=120`
										: '/images/default-profile.webp',
									iconImageSize: [25, 25],
								}}
							/>
						)
					})}
				</Map>
			</YMaps>
		</div>
	)
}
