import { useRef } from 'react'

const useLoader = () => {
	const btnRef = useRef(null)

	const toggleLoader = isLoading => {
		const btn = btnRef.current
		if (btn) {
			const btnText = btn.querySelector('span')
			const btnSpinner = btn.querySelector('div')
			if (btnText && btnSpinner) {
				btnText.style.display = isLoading ? 'none' : ''
				btnSpinner.style.display = isLoading ? 'flex' : 'none'
			}
			btn.disabled = isLoading
		}
	}

	return { btnRef, toggleLoader }
}

export default useLoader
