/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useLoaderData } from 'react-router-dom'
import ContentWrapper from '../components/content-wrapper'
import TasksList from '../components/task/task-list'
import MapComponent from '../components/task/map'

export default function FeedPage() {
	const tasks = useLoaderData()
	const user = useAuthUser()

	useEffect(() => {
		const pageIndicator = document.getElementById('page-indicator')

		if (pageIndicator) pageIndicator.textContent = 'Топ задания 🔥'
	}, [])

	const isBusiness = user.type === 'business'

	return (
		<>
			<ContentWrapper
				className={`${
					isBusiness
						? '[&]:lg:w-[calc(100%-192px)] [&]:xl:w-[calc(100%-216px)]'
						: 'order-2 lg:order-1 [&]:pt-3 [&]:lg:p-6 [&]:xl:p-7'
					} `}
				childClass={isBusiness ? '' : '[&]:pt-0'}
			>
				<h1 className='mb-4 hidden text-2xl font-semibold text-primary-500 lg:block'>
					Топ задания 🔥
				</h1>
				<div className='size-full'>
					<TasksList
						items={tasks.data}
						authUser={user}
						className='scrollbar-hide lg:size-full lg:overflow-y-scroll lg:pb-12'
					/>
				</div>
			</ContentWrapper>
			{!isBusiness && (
				<MapComponent
					className='order-1 h-96 w-screen lg:order-2 lg:h-full lg:w-2/5'
					items={tasks.data}
					user={user}
				/>
			)}
		</>
	)
}
