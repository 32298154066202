import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export default function PathListener() {
	const location = useLocation()

	useEffect(() => {
		const links = document.querySelectorAll('header nav a')

		links.forEach(link => {
			link.classList.toggle(
				'nav-link-active',
				location.pathname.includes(link.getAttribute('href'))
			)
		})
	}, [location])

	return null
}
