import DealPreview from './deal-preview'

export default function DealList({ className, deals, userId, id }) {
	const getAnotherUser = arr =>
		arr.filter(item => item.id !== userId)?.[0]?.attributes || {}

	const linkState = { prevPath: '/deals', notPath: true }

	return (
		<div className={`flex flex-col ${className}`} id={id}>
			{deals.map(item => (
				<DealPreview
					key={item.id}
					linkState={linkState}
					itemId={item.id}
					user={getAnotherUser(item.attributes?.users?.data)}
					className='mt-2 first:mt-0'
				/>
			))}
		</div>
	)
}
