export default function LogoApp(props) {
	return (
		<svg
			width='147'
			height='25'
			viewBox='0 0 147 25'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.0769 4.9199C14.139 4.9199 15 4.04239 15 2.95995C15 1.8775 14.139 1 13.0769 1C12.0148 1 11.1538 1.8775 11.1538 2.95995C11.1538 4.04239 12.0148 4.9199 13.0769 4.9199ZM3.55769 7.56583H0V16.3758V16.3856C0.0103739 20.5927 3.35996 24 7.49039 24C11.6208 24 14.9704 20.5927 14.9807 16.3856H14.9808V7.56583H11.4231V16.3758C11.4179 18.5849 9.65913 20.3741 7.49039 20.3741C5.32162 20.3741 3.56288 18.5849 3.55769 16.3758V7.56583Z'
				fill='currentColor'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.0745 4.9199C26.1379 4.9199 27 4.04239 27 2.95995C27 1.8775 26.1379 1 25.0745 1C24.011 1 23.1489 1.8775 23.1489 2.95995C23.1489 4.04239 24.011 4.9199 25.0745 4.9199ZM23.4377 7.56583H27V16.3856C26.9896 20.5927 23.6357 24 19.5 24C17.842 24 16.3096 23.4524 15.0679 22.5251C15.8523 21.5991 16.4629 20.5162 16.8483 19.3291C17.548 19.9783 18.4786 20.3741 19.5 20.3741C21.6716 20.3741 23.4325 18.5849 23.4377 16.3758V7.56583ZM12 16.3758C12.0023 18.2501 12.6683 19.9662 13.7715 21.2937C14.5091 20.4062 15.0512 19.345 15.3302 18.179C15.4686 17.6009 15.5422 16.997 15.543 16.3758H12Z'
				fill='currentColor'
				fillOpacity='0.5'
			/>
			<path
				d='M132.458 0H135.394V16.1346L143.355 8.03194H147L140.029 15.5331L147 24.3081H143.355L137.836 17.3731L135.394 19.7437V24.3081H132.458V0Z'
				fill='currentColor'
			/>
			<path
				d='M120.882 8.03193H123.819L122.863 12.3133L123.819 12.6317V24.3081H120.882V8.03193ZM128.843 10.721C127.475 10.721 126.295 11.1103 125.305 11.8887C124.314 12.6435 123.819 13.6224 123.819 14.8255L123.359 14.224C123.359 12.3133 123.878 10.7918 124.916 9.65955C125.953 8.5037 127.345 7.92578 129.091 7.92578H130.329V10.721H128.843Z'
				fill='currentColor'
			/>
			<path
				d='M105.562 16.099C105.562 18.0804 105.987 19.6137 106.836 20.6988C107.685 21.7603 108.864 22.291 110.374 22.291C111.907 22.291 113.099 21.7603 113.948 20.6988C114.821 19.6137 115.257 18.0804 115.257 16.099C115.257 14.1411 114.821 12.6314 113.948 11.57C113.099 10.4849 111.907 9.94233 110.374 9.94233C108.864 9.94233 107.685 10.4849 106.836 11.57C105.987 12.6314 105.562 14.1411 105.562 16.099ZM102.59 16.099C102.59 13.3155 103.286 11.1689 104.677 9.65927C106.093 8.12601 107.992 7.35938 110.374 7.35938C112.615 7.35938 114.479 8.1378 115.965 9.69465C117.474 11.2515 118.229 13.3863 118.229 16.099C118.229 18.8824 117.521 21.0526 116.106 22.6094C114.691 24.1427 112.78 24.9093 110.374 24.9093C107.992 24.9093 106.093 24.1427 104.677 22.6094C103.286 21.0526 102.59 18.8824 102.59 16.099Z'
				fill='currentColor'
			/>
			<path
				d='M88.0095 12.7382L84.9666 24.3084H80.473L75.6255 8.03223H78.6684L82.1713 19.6025L82.4898 21.5839H82.9498L83.339 19.6025L86.4173 8.03223H90.5925L93.7416 19.6025L94.0954 21.5839H94.5554L94.8738 19.6025L98.3768 8.03223H101.384L96.5722 24.3084H92.0786L89.0003 12.7382L88.7526 10.5798H88.328L88.0095 12.7382Z'
				fill='currentColor'
			/>
			<path
				d='M61.7822 16.099C61.7822 18.0804 62.2068 19.6137 63.056 20.6988C63.9052 21.7603 65.0846 22.291 66.5943 22.291C68.1276 22.291 69.3188 21.7603 70.168 20.6988C71.0408 19.6137 71.4772 18.0804 71.4772 16.099C71.4772 14.1411 71.0408 12.6314 70.168 11.57C69.3188 10.4849 68.1276 9.94233 66.5943 9.94233C65.0846 9.94233 63.9052 10.4849 63.056 11.57C62.2068 12.6314 61.7822 14.1411 61.7822 16.099ZM58.8101 16.099C58.8101 13.3155 59.5059 11.1689 60.8977 9.65927C62.313 8.12601 64.2119 7.35938 66.5943 7.35938C68.8352 7.35938 70.6987 8.1378 72.1848 9.69465C73.6945 11.2515 74.4493 13.3863 74.4493 16.099C74.4493 18.8824 73.7417 21.0526 72.3264 22.6094C70.911 24.1427 69.0004 24.9093 66.5943 24.9093C64.2119 24.9093 62.313 24.1427 60.8977 22.6094C59.5059 21.0526 58.8101 18.8824 58.8101 16.099Z'
				fill='currentColor'
			/>
			<path
				d='M53.2256 0H56.1624V24.3081H53.2256V0Z'
				fill='currentColor'
			/>
			<path
				d='M45.7433 24.3081H42.7711V10.5795H39.5513V8.03194H42.7711V5.59051C42.7711 3.5383 43.2075 2.09939 44.0803 1.27379C44.9531 0.424596 46.392 0 48.397 0H50.6615V2.54758H48.2555C47.3827 2.54758 46.7458 2.80705 46.3448 3.326C45.9438 3.84495 45.7433 4.62338 45.7433 5.66128V8.03194H51.0507V10.5795H45.7433V24.3081Z'
				fill='currentColor'
			/>
		</svg>
	)
}
