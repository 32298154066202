import { useRef } from 'react'
import useLoader from '../../utils/use-loader'
import Spinner from '../../icons/spinner'
import { emailRegex } from '../../../config/variables'

export default function RequestResetPasswordForm({ className }) {
	const { btnRef, toggleLoader } = useLoader()
	const labelRef = useRef(null)

	const toggleError = isDisabled => {
		labelRef.current?.classList?.toggle('input-error', isDisabled)
	}

	const handleChange = () => {
		toggleError(false)
	}

	const onSubmit = () => {
		toggleLoader(true)

		const input = labelRef.current?.querySelector('input')

		try {
			const { value } = input

			if (!emailRegex.test(value)) {
				throw new Error('validation error')
			}

			fetch('https://api.flowork.ru/api/auth/forgot-password', {
				method: 'POST',
				headers: { 'Content-type': 'application/json' },
				body: JSON.stringify({
					email: value,
				}),
			})
				.then(res => {
					if (res.status === 200) {
						const submitWindow =
							document.getElementById('submit-window')
						if (submitWindow) submitWindow.style.display = 'flex'
						toggleLoader(false)
					} else {
						throw new Error('status error')
					}
				})
				.catch(error => {
					throw new Error(error)
				})
		} catch {
			toggleError(true)
			toggleLoader(false)
		}
	}

	return (
		<div className={`flex flex-col ${className}`}>
			<label
				ref={labelRef}
				className='flex flex-col rounded-xl border border-[#E2E2E2]/40 bg-white/10 px-4
					py-2 text-left text-xs text-white/60 transition-colors'
				htmlFor='email'
			>
				<span className='text-xs'>Почта</span>
				<input
					type='text'
					className='appearance-none border-none bg-transparent text-base text-white
						outline-none'
					onChange={handleChange}
				/>
			</label>
			<button
				type='button'
				onClick={onSubmit}
				ref={btnRef}
				className='mt-2 flex flex-row items-center justify-center rounded-xl bg-white p-4
					text-center text-base font-semibold text-primary-500
					*:pointer-events-none'
			>
				<span>Отправить ссылку для сброса</span>
				<Spinner
					className='size-6 rounded-full fill-primary-500 text-gray-200'
					style={{ display: 'none' }}
				/>
			</button>
		</div>
	)
}
