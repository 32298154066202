import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useRef } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'

export default function PageIndicator() {
	const navigate = useNavigate()
	const location = useLocation()
	const backBtnRef = useRef(null)
	const divRef = useRef(null)
	const user = useAuthUser()

	useEffect(() => {
		backBtnRef.current.classList.toggle(
			'hidden',
			location.pathname.split('/').length <= 2
		)
	}, [location])

	useEffect(() => {
		const handleScroll = () => {
			if (divRef.current) {
				divRef.current.classList.toggle(
					'indicator-hidden',
					window.scrollY < 120
				)
			}
		}

		if (location.pathname === '/feed') {
			if (user.type === 'blogger') {
				window.addEventListener('scroll', handleScroll)
				handleScroll()
			}
		} else {
			window.removeEventListener('scroll', handleScroll)
			if (divRef.current) {
				divRef.current.classList.remove('indicator-hidden')
			}
		}

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [location, user])

	const handleClick = useCallback(() => {
		navigate(location.state?.prevPath || '/')
	}, [location.state, navigate])

	return (
		<div
			className='safe-content-t fixed left-0 top-0 z-10 flex h-auto w-full items-center
				justify-center bg-white'
			ref={divRef}
		>
			<div
				className='flex h-auto w-full flex-row items-center justify-center bg-white px-4
					py-3 text-primary-500 lg:hidden'
			>
				<button
					type='button'
					aria-label='Вернуться назад'
					ref={backBtnRef}
					onClick={handleClick}
					className='absolute left-2.5 hidden'
					id='page-indicator-back-btn'
				>
					<img
						src='/icons/chevron-left.svg'
						width={30}
						height={30}
						alt=''
						className='pointer-events-none size-7'
					/>
				</button>
				<div
					className='whitespace-nowrap text-lg font-semibold text-primary-500'
					id='page-indicator'
				>
					Доступные офферы
				</div>
			</div>
		</div>
	)
}
