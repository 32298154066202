import LogoApp from '../icons/logo-app'

export default function Intro({ className = '' }) {
	return (
		<div className={`flex flex-col ${className}`}>
			<LogoApp className='h-16 w-64' />
			<h1 className='mt-4 text-lg sm:mt-8'>
				Платформа, объединяющая
				<br />
				блогеров и бизнес
			</h1>
		</div>
	)
}
