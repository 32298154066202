import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	Navigate,
	Outlet,
	ScrollRestoration,
} from 'react-router-dom'

import createStore from 'react-auth-kit/createStore'
import AuthProvider from 'react-auth-kit'
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import * as requests from './config/requests'

import Layout from './components/layout'
import Error from './components/error'
import ProfileLayout from './pages/profile-layout'
import RegisterLayout from './pages/auth/register/layout'

import FeedPage from './pages/feed'
import UserFeedPage from './pages/user-feed'
import LoginPage from './pages/auth/login'
import ResetPasswordPage from './pages/auth/reset-password'
import ProfilePage from './pages/profile'
import CreateOfferPage from './pages/create-offer'
import FavouritesPage from './pages/favourites'
import DealsPage from './pages/deals'
import DealPage from './pages/deal'
import BloggerRegisterPage from './pages/auth/register/blogger'
import BusinessRegisterPage from './pages/auth/register/business'
import '@capacitor-community/safe-area'

const store = createStore({
	authName: '_auth',
	authType: 'localstorage',
})

const getAuthStore = () => {
	const { userState, auth } = store.tokenObject?.authValue || {
		userState: null,
		auth: null,
	}

	const authHeader = `${auth?.type} ${auth?.token}`

	return { userState, authHeader }
}

function AuthLayout() {
	return (
		<>
			<Outlet />
			<ScrollRestoration />
		</>
	)
}

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path='/auth' element={<AuthLayout />}>
				<Route path='login' element={<LoginPage />} />
				<Route path='resetpassword' element={<ResetPasswordPage />} />
				<Route path='register' element={<RegisterLayout />}>
					<Route path='blogger' element={<BloggerRegisterPage />} />
					<Route path='business' element={<BusinessRegisterPage />} />
				</Route>
			</Route>
			<Route
				element={<AuthOutlet fallbackPath='/auth/login' />}
				errorElement={<Error />}
			>
				<Route path='/' element={<Layout />}>
					<Route index element={<Navigate to='/feed' />} />
					<Route
						path='feed'
						element={<FeedPage />}
						loader={async () => {
							const { userState } = getAuthStore()
							return fetch(requests.feed(userState.type))
						}}
					/>
					<Route
						path='feed/:userId'
						loader={async ({ params }) => {
							const [userInfo, userTasks] = await Promise.all([
								fetch(requests.userInfo(params.userId)).then(res =>
									res.json()
								),
								fetch(requests.tasksUser(params.userId)).then(res =>
									res.json()
								),
							])
							return { userInfo, userTasks }
						}}
						element={<UserFeedPage />}
					/>
					<Route
						path='favourites'
						element={<FavouritesPage />}
						loader={async () => {
							const { userState } = getAuthStore()
							return fetch(requests.feed(userState.type))
						}}
					/>
					<Route
						path='deals'
						element={<DealsPage />}
						loader={async () => {
							const { userState } = getAuthStore()

							// const chatsReq = await fetch(
							// 	requests.userDeals(userState?.id)
							// )
							// const { data: chats } = await chatsReq.json()

							// const messagesReq = await fetch(
							// 	requests.messages(chats)
							// )
							// const { data: messages } = await messagesReq.json()

							// return { chats, messages }

							return fetch(requests.userDeals(userState?.id))
						}}
					>
						<Route
							path=':chatId'
							element={<DealPage />}
							loader={async ({ params }) => {
								const { authHeader } = getAuthStore()
								return fetch(requests.deal(params.chatId), {
									headers: {
										Authorization: authHeader,
									},
								})
							}}
						/>
					</Route>
					<Route path='create' element={<CreateOfferPage />} />
					<Route path='profile' element={<ProfileLayout />}>
						<Route
							index
							element={<ProfilePage />}
							loader={async () => {
								const { userState } = getAuthStore()
								return fetch(requests.userInfo(userState?.id))
							}}
						/>
					</Route>
				</Route>
			</Route>
		</>
	)
)

ReactDOM.createRoot(document.getElementById('root')).render(
	<AuthProvider store={store}>
		<RouterProvider router={router} />
	</AuthProvider>
)
