import PriceCard from './icons/price-card'

export default function PriceView({
	value,
	className,
	isDark = true,
}) {
	return (
		<div
			className={`flex w-fit flex-row items-center rounded-full bg-primary-500 px-2.5
				py-1.5 ${className}
				${isDark ? 'bg-primary-500 text-white' : 'bg-white text-primary-500'}`}
		>
			<PriceCard
				className={`mr-1 size-5 ${isDark ? 'fill-white' : 'fill-primary-500'}`}
			/>
			<span className='text-sm font-semibold'>
				{value ? `${value.toLocaleString('ru-RU')} ₽` : 'Договорная'}
			</span>
		</div>
	)
}
