import PriceView from '../price-view'

export default function TaskContent({ description, price }) {
	return (
		<div className='flex flex-col rounded-xl border border-primary-500/10 p-4'>
			<span className='text-base text-black'>{description}</span>
			<PriceView value={price} className='mt-4' />
		</div>
	)
}
