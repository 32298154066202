export function Message(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M7.7959 23.2168C9.01758 23.2168 11.4873 22.0303 13.3506 20.7031C19.5557 20.8174 24.1963 17.2314 24.1963 12.5205C24.1963 7.97656 19.6523 4.32031 13.9922 4.32031C8.33203 4.32031 3.79688 7.97656 3.79688 12.5205C3.79688 15.4121 5.58984 17.9873 8.34082 19.332C7.96289 20.0439 7.29492 21.0195 6.91699 21.5293C6.35449 22.2764 6.74121 23.2168 7.7959 23.2168ZM8.85938 21.3975C8.74512 21.4414 8.70117 21.3799 8.78027 21.2832C9.22852 20.7383 9.8877 19.8682 10.2129 19.2529C10.4766 18.7695 10.3887 18.3477 9.82617 18.084C7.14551 16.8271 5.58984 14.8145 5.58984 12.5205C5.58984 8.9873 9.31641 6.11328 13.9922 6.11328C18.668 6.11328 22.3945 8.9873 22.3945 12.5205C22.3945 16.0537 18.668 18.9365 13.9922 18.9365C13.834 18.9365 13.5879 18.9277 13.2891 18.9277C12.9111 18.9189 12.6299 19.0332 12.2783 19.3057C11.2148 20.0967 9.6416 21.0811 8.85938 21.3975Z' />
		</svg>
	)
}

export function MessageFill(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M7.33008 23.1201C8.40234 23.1201 10.96 22.0039 12.5596 20.8789C12.7266 20.7559 12.8672 20.7119 13.0078 20.7119C13.1221 20.7207 13.2275 20.7295 13.3242 20.7295C19.6611 20.7207 24.1963 17.2666 24.1963 12.5205C24.1963 7.97656 19.6523 4.32031 13.9922 4.32031C8.33203 4.32031 3.79688 7.97656 3.79688 12.5205C3.79688 15.3506 5.50195 17.8818 8.38477 19.4375C8.54297 19.5342 8.58691 19.666 8.50781 19.8242C7.99805 20.6768 7.14551 21.626 6.79395 22.0742C6.39844 22.5752 6.62695 23.1201 7.33008 23.1201Z' />
		</svg>
	)
}

export function Favourite(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M5.09766 11.1846C5.09766 14.9375 8.25293 18.6465 13.1572 21.8105C13.4297 21.9863 13.7637 22.1621 13.9922 22.1621C14.2207 22.1621 14.5547 21.9863 14.8271 21.8105C19.7402 18.6465 22.8867 14.9375 22.8867 11.1846C22.8867 7.94141 20.6455 5.69141 17.7715 5.69141C16.0928 5.69141 14.7832 6.45605 13.9922 7.61621C13.2188 6.46484 11.9004 5.69141 10.2217 5.69141C7.33887 5.69141 5.09766 7.94141 5.09766 11.1846ZM6.9082 11.1758C6.9082 8.96094 8.36719 7.45801 10.3271 7.45801C11.9092 7.45801 12.7969 8.41602 13.3506 9.25098C13.5967 9.61133 13.7637 9.72559 13.9922 9.72559C14.2295 9.72559 14.3789 9.60254 14.6338 9.25098C15.2314 8.43359 16.084 7.45801 17.6572 7.45801C19.626 7.45801 21.085 8.96094 21.085 11.1758C21.085 14.2695 17.8682 17.6973 14.1592 20.1582C14.0801 20.2109 14.0273 20.2461 13.9922 20.2461C13.957 20.2461 13.9043 20.2109 13.834 20.1582C10.125 17.6973 6.9082 14.2695 6.9082 11.1758Z' />
		</svg>
	)
}

export function FavouriteFill(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M13.9922 22.1621C14.2207 22.1621 14.5547 21.9863 14.8271 21.8105C19.7402 18.6465 22.8867 14.9375 22.8867 11.1846C22.8867 7.94141 20.6455 5.69141 17.8418 5.69141C16.0928 5.69141 14.7832 6.6582 13.9922 8.09961C13.2188 6.6582 11.9004 5.69141 10.1514 5.69141C7.33887 5.69141 5.09766 7.94141 5.09766 11.1846C5.09766 14.9375 8.25293 18.6465 13.1572 21.8105C13.4297 21.9863 13.7637 22.1621 13.9922 22.1621Z' />
		</svg>
	)
}

export function Profile(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M14 13.8477C16.127 13.8477 17.8496 11.9668 17.8496 9.66406C17.8496 7.39648 16.127 5.59473 14 5.59473C11.8818 5.59473 10.1416 7.42285 10.1504 9.68164C10.1592 11.9756 11.873 13.8477 14 13.8477ZM14 12.3096C12.7871 12.3096 11.7588 11.1582 11.7588 9.68164C11.75 8.24023 12.7783 7.13281 14 7.13281C15.2305 7.13281 16.2412 8.22266 16.2412 9.66406C16.2412 11.1406 15.2217 12.3096 14 12.3096ZM8.51562 22.0215H19.4756C20.9961 22.0215 21.7256 21.5381 21.7256 20.501C21.7256 18.084 18.7109 14.8672 14 14.8672C9.28906 14.8672 6.26562 18.084 6.26562 20.501C6.26562 21.5381 6.99512 22.0215 8.51562 22.0215ZM8.24316 20.4834C8.03223 20.4834 7.95312 20.4131 7.95312 20.2549C7.95312 18.9102 10.124 16.4053 14 16.4053C17.8672 16.4053 20.0381 18.9102 20.0381 20.2549C20.0381 20.4131 19.959 20.4834 19.748 20.4834H8.24316Z' />
		</svg>
	)
}

export function ProfileFill(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M14.0098 13.5752C15.9258 13.5752 17.5518 11.8701 17.5518 9.66406C17.5518 7.51074 15.917 5.8584 14.0098 5.8584C12.0938 5.8584 10.4502 7.53711 10.459 9.68164C10.459 11.8701 12.085 13.5752 14.0098 13.5752ZM8.52539 21.749H19.4766C20.9268 21.749 21.4277 21.3096 21.4277 20.501C21.4277 18.2422 18.5625 15.1309 14.001 15.1309C9.44824 15.1309 6.57422 18.2422 6.57422 20.501C6.57422 21.3096 7.0752 21.749 8.52539 21.749Z' />
		</svg>
	)
}

export function Feed(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM9.20996 9.53223V11.5713C9.20996 12.1426 9.54395 12.4678 10.1064 12.4678H12.1455C12.7168 12.4678 13.0508 12.1426 13.0508 11.5713V9.53223C13.0508 8.96094 12.7168 8.63574 12.1455 8.63574H10.1064C9.54395 8.63574 9.20996 8.96094 9.20996 9.53223ZM9.75488 15.3945H18.1748C18.4912 15.3945 18.7285 15.1484 18.7285 14.832C18.7285 14.5244 18.4912 14.2871 18.1748 14.2871H9.75488C9.42969 14.2871 9.19238 14.5244 9.19238 14.832C9.19238 15.1484 9.42969 15.3945 9.75488 15.3945ZM9.75488 18.2949H15.9775C16.2939 18.2949 16.5312 18.0488 16.5312 17.7412C16.5312 17.4248 16.2939 17.1787 15.9775 17.1787H9.75488C9.42969 17.1787 9.19238 17.4248 9.19238 17.7412C9.19238 18.0488 9.42969 18.2949 9.75488 18.2949Z' />
		</svg>
	)
}

export function FeedFill(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.73535 8.89941C8.73535 8.31055 9.08691 7.95898 9.68457 7.95898H11.8027C12.4004 7.95898 12.752 8.31055 12.752 8.89941V11.0352C12.752 11.6152 12.4004 11.9756 11.8027 11.9756H9.68457C9.08691 11.9756 8.73535 11.6152 8.73535 11.0352V8.89941ZM9.24512 16.0273C8.91113 16.0273 8.66504 15.7725 8.66504 15.4473C8.66504 15.1396 8.91113 14.8936 9.24512 14.8936H17.9463C18.2715 14.8936 18.5176 15.1396 18.5176 15.4473C18.5176 15.7725 18.2715 16.0273 17.9463 16.0273H9.24512ZM9.24512 19.0332C8.91113 19.0332 8.66504 18.7959 8.66504 18.4795C8.66504 18.1543 8.91113 17.8994 9.24512 17.8994H15.7227C16.0479 17.8994 16.2939 18.1543 16.2939 18.4795C16.2939 18.7959 16.0479 19.0332 15.7227 19.0332H9.24512Z' />
		</svg>
	)
}

export function Add(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M13.9902 22.7422C18.9736 22.7422 23.0869 18.6289 23.0869 13.6543C23.0869 8.67969 18.9648 4.56641 13.9814 4.56641C9.00684 4.56641 4.90234 8.67969 4.90234 13.6543C4.90234 18.6289 9.01562 22.7422 13.9902 22.7422ZM13.9902 20.9316C9.95605 20.9316 6.73047 17.6885 6.73047 13.6543C6.73047 9.62012 9.95605 6.38574 13.9814 6.38574C18.0156 6.38574 21.2588 9.62012 21.2676 13.6543C21.2764 17.6885 18.0244 20.9316 13.9902 20.9316ZM10.7471 14.4893H13.1553V16.9062C13.1553 17.3896 13.498 17.7324 13.9727 17.7324C14.4648 17.7324 14.8164 17.3896 14.8164 16.9062V14.4893H17.2334C17.7168 14.4893 18.0684 14.1465 18.0684 13.6631C18.0684 13.1797 17.7256 12.8281 17.2334 12.8281H14.8164V10.4111C14.8164 9.91895 14.4648 9.57617 13.9727 9.57617C13.498 9.57617 13.1553 9.91895 13.1553 10.4111V12.8281H10.7471C10.2461 12.8281 9.90332 13.1797 9.90332 13.6631C9.90332 14.1465 10.2549 14.4893 10.7471 14.4893Z'
				fill='#0465F9'
			/>
		</svg>
	)
}

export function AddFill(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M13.9902 22.7422C18.9736 22.7422 23.0869 18.6289 23.0869 13.6543C23.0869 8.67969 18.9648 4.56641 13.9814 4.56641C9.00684 4.56641 4.90234 8.67969 4.90234 13.6543C4.90234 18.6289 9.01562 22.7422 13.9902 22.7422ZM9.73633 13.6631C9.73633 13.1445 10.1055 12.7842 10.6152 12.7842H13.1201V10.2793C13.1201 9.76953 13.4717 9.40918 13.9727 9.40918C14.4912 9.40918 14.8516 9.76953 14.8516 10.2793V12.7842H17.3652C17.8662 12.7842 18.2354 13.1445 18.2354 13.6631C18.2354 14.1641 17.8662 14.5244 17.3652 14.5244H14.8516V17.0293C14.8516 17.5303 14.4912 17.8994 13.9727 17.8994C13.4717 17.8994 13.1201 17.5303 13.1201 17.0293V14.5244H10.6152C10.1055 14.5244 9.73633 14.1641 9.73633 13.6631Z'
				fill='#0465F9'
			/>
		</svg>
	)
}
