export default function RestrictedField({ handleClose }) {
	return (
		<>
			<div className='mb-2 text-center text-xl font-semibold text-primary-500'>
				Поля ссылки нельзя&nbsp;редактировать
			</div>
			<div className='text-center text-base text-black'>
				Обратитесь в&nbsp;службу поддержки, чтобы изменить
				верифицированные данные
			</div>
			<a
				href='https://google.com'
				rel='noopener noreferrer'
				target='_blank'
				className='mt-4 flex w-full flex-row items-center justify-center rounded-xl
					bg-primary-500 px-4 py-3.5 text-base font-semibold text-white'
			>
				<span>Написать в поддержку</span>
			</a>
			<button
				type='button'
				onClick={handleClose}
				aria-label='Закрыть'
				className='mt-2 flex items-center justify-center rounded-xl bg-gray-200 px-4
					py-3.5 text-base font-semibold text-black'
			>
				Я понял
			</button>
		</>
	)
}
