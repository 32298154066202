import useSignOut from 'react-auth-kit/hooks/useSignOut'
import { useNavigate } from 'react-router-dom'
import useLoader from '../utils/use-loader'
import Spinner from '../icons/spinner'

export default function DeleteAccountForm({
	handleClose,
	authHeader,
	userId,
}) {
	const signOut = useSignOut()
	const navigate = useNavigate()

	const { btnRef, toggleLoader } = useLoader()

	const toggleError = () => {
		const btn = btnRef.current
		if (btn) {
			btn.classList.add('btn-error')
			setTimeout(() => {
				btn.classList.remove('btn-error')
			}, 3000)
		}
	}

	const handleSubmit = () => {
		toggleLoader(true)
		fetch(`https://api.flowork.ru/api/users/${userId}`, {
			method: 'DELETE',
			headers: {
				Authorization: authHeader,
			},
		})
			.then(res => {
				toggleLoader(false)
				if (res.status === 200) {
					signOut()
					navigate('/auth/login')
				} else {
					toggleError()
				}
			})
			.catch(() => {
				toggleLoader(false)
				toggleError()
			})
	}

	return (
		<>
			<div className='mb-2 text-center text-xl font-semibold text-primary-500'>
				Вы уверены, что хотите
				<br />
				удалить аккаунт?
			</div>
			<div className='text-center text-base text-black'>
				Это действие будет
				<br />
				невозможно отменить
			</div>
			<button
				onClick={handleClose}
				type='button'
				className='mt-4 flex w-full flex-row items-center justify-center rounded-xl
					bg-primary-500 px-4 py-3.5 text-base font-semibold text-white'
			>
				<span>Оставить аккаунт</span>
			</button>
			<button
				type='button'
				onClick={handleSubmit}
				ref={btnRef}
				aria-label='Закрыть'
				className='mt-2 flex items-center justify-center rounded-xl bg-gray-200 px-4
					py-3.5 text-base font-semibold text-black transition-colors
					*:pointer-events-none'
			>
				<span>Я понимаю, хочу&nbsp;удалить</span>
				<Spinner
					className='size-6 rounded-full fill-gray-400 text-white'
					style={{ display: 'none' }}
				/>
			</button>
		</>
	)
}
