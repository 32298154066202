import Bolt from '../icons/bolt'
import toggleModal from '../utils/toggle-modal'

export default function UserInfoCard({ userInfo, isBusiness }) {
	const avatar = userInfo.avatar?.url
	const profileCoords = userInfo.profile.coords

	return (
		<div
			className='relative flex w-full flex-col justify-between rounded-2xl border
				border-primary-500/10 p-4 text-black'
		>
			<div className='flex flex-row items-center'>
				<img
					src={
						avatar
							? `https://api.flowork.ru${avatar}?format=webp&w=240`
							: '/images/default-profile.webp'
					}
					alt='Аватар'
					className='size-20 shrink-0 rounded-full object-cover'
				/>
				<div className='ml-4 flex flex-col'>
					{userInfo.profile.address && !isBusiness && (
						<span className='text-base opacity-60'>
							{userInfo.profile.address}
						</span>
					)}
					{userInfo.blogger?.subscribers && (
						<span className='text-base'>
							{userInfo.blogger.subscribers}
						</span>
					)}
					<div className='-m-1 mt-1 flex flex-row flex-wrap'>
						{!isBusiness && profileCoords && (
							<a
								target='_blank'
								href={`https://maps.yandex.ru/?text=${profileCoords.split(', ').join('+')}`}
								className='decoration-skip-ink-none m-1 text-base font-semibold text-primary-500
									underline decoration-2 underline-offset-[6px]'
								rel='noreferrer'
							>
								Маршрут
							</a>
						)}
						{userInfo.socialLinks.map(item => (
							<a
								key={item.label}
								target='_blank'
								href={item.link}
								className='decoration-skip-ink-none m-1 text-base font-semibold text-primary-500
									underline decoration-2 underline-offset-[6px]'
								rel='noreferrer'
							>
								{item.label}
							</a>
						))}
					</div>
				</div>
			</div>
			<div className='mt-3'>
				<span>
					{userInfo.profile.description || 'Описание отсутствует'}
				</span>
			</div>
			<div className='mt-3'>
				<button
					data-modal-id='subscribe-modal'
					onClick={toggleModal}
					type='button'
					className='flex flex-row items-center *:pointer-events-none'
				>
					<Bolt className='mr-1 size-6 fill-primary-500' />
					<span className='font-semibold text-primary-500'>
						Оставить отзыв
					</span>
				</button>
			</div>
		</div>
	)
}
