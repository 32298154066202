export const socialLinks = [
	'Youtube',
	'VK',
	'Instagram',
	'X (Twitter)',
]

export const blogThemes = [
	'Путешествия',
	'Еда и кулинария',
	'Здоровье и фитнес',
	'Мода и стиль',
	'Технологии',
	'Личное развитие',
	'Финансы',
	'Семья и дети',
	'Игры и гейминг',
	'Другое',
]

export const urlRegex =
	/^(https?:\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9._-]*)*\/?(\?[a-zA-Z0-9=&_-]*)?(#[a-zA-Z0-9_-]*)?$/

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i
