import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function DealPathListener({
	listContainerId,
	contentContainerId,
}) {
	const location = useLocation()

	useEffect(() => {
		const isContent = location.pathname.split('/').length > 2
		const pageIndicator = document.getElementById('page-indicator')
		const listContainer = document.getElementById(listContainerId)
		const contentContainer = document.getElementById(
			contentContainerId
		)

		if (listContainer && contentContainer) {
			listContainer.querySelectorAll('a').forEach(link => {
				link.classList.toggle(
					'chat-active',
					link.getAttribute('href') === location.pathname
				)
			})

			listContainer.classList.toggle(
				'container-chat-active',
				isContent
			)

			contentContainer.classList.toggle(
				'chat-content-active',
				isContent
			)
		}

		if (pageIndicator)
			pageIndicator.textContent = isContent
				? 'Детали сделки'
				: 'Ваши сделки'
	}, [location, listContainerId, contentContainerId])
}
