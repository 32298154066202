import { useEffect } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useLoaderData } from 'react-router-dom'
import ContentWrapper from '../components/content-wrapper'
import TasksList from '../components/task/task-list'

export default function FeedPage() {
	const tasks = useLoaderData()
	const user = useAuthUser()

	useEffect(() => {
		const pageIndicator = document.getElementById('page-indicator')

		if (pageIndicator) pageIndicator.textContent = 'Избранное'
	}, [])

	const isBusiness = user.type === 'business'

	const favouritesArr = JSON.parse(
		localStorage.getItem('favourites') || '[]'
	)

	return (
		<ContentWrapper
			className={
				isBusiness
					? '[&]:lg:w-[calc(100%-192px)] [&]:xl:w-[calc(100%-216px)]'
					: ''
			}
		>
			<h1 className='mb-4 hidden text-2xl font-semibold text-primary-500 lg:block'>
				Избранное
			</h1>
			{favouritesArr.length < 1 && (
				<div className='text-sm text-black/60'>
					Ваша избранная коллекция пока пуста.
					<br />
					Добавляйте понравившиеся офферы из ленты.
				</div>
			)}
			<TasksList
				className='w-full'
				items={tasks.data.filter(item =>
					favouritesArr.includes(item.id)
				)}
				isFavourite
				authUser={user}
			/>
		</ContentWrapper>
	)
}
