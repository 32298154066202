import { useRef } from 'react'
import { socialLinks, urlRegex } from '../../config/variables'
import Spinner from '../icons/spinner'
import useLoader from '../utils/use-loader'

export default function AddSocialForm({
	initial = [],
	userId,
	authHeader,
	handleClose,
}) {
	const labelRef = useRef(null)
	const accordionRef = useRef(null)
	const accordionBtnRef = useRef(null)

	const { btnRef, toggleLoader } = useLoader()

	const handleAccordionClick = event => {
		if (accordionRef.current) {
			accordionRef.current.classList.toggle('accordion-open')
			event.target
				.querySelector('#chevron')
				?.classList?.toggle('rotate-90')
		}
	}

	const handleSocialButtonClick = event => {
		const accordion = accordionRef.current
		const input = labelRef.current?.querySelector('input')
		const accordionBtn = accordionBtnRef.current
		const { value } = event.target.dataset

		if (accordion && input && accordionBtnRef) {
			accordion.classList.toggle('accordion-open')
			accordionBtn
				.querySelector('#chevron')
				?.classList?.toggle('rotate-90')

			const accordionText =
				accordionBtn.querySelector('#accordion-text')

			if (accordionText) {
				accordionText.textContent = value
				input.setAttribute('data-target', value)
				input.value = ''
			}
		}
	}

	const handleSubmit = () => {
		toggleLoader(true)
		const input = labelRef.current?.querySelector('input')
		const label =
			accordionBtnRef.current?.querySelector('#accordion-text')

		try {
			const { value } = input

			if (urlRegex.test(value) && label) {
				const data = [
					...initial.map(item => {
						const { id, ...itemProperties } = item
						return itemProperties
					}),
					{
						label: label.textContent,
						link: value,
					},
				]

				fetch(`https://api.flowork.ru/api/users/${userId}`, {
					method: 'PUT',
					headers: {
						'Content-type': 'application/json',
						Authorization: authHeader,
					},
					body: JSON.stringify({
						socialLinks: data,
					}),
				})
					.then(res => {
						if (res.status !== 200) {
							throw new Error('Invalid Status')
						} else {
							toggleLoader(false)
							handleClose()
						}
					})
					.catch(() => {
						toggleLoader(false)
						labelRef.current.classList.add('input-error-dark')
					})
			} else {
				throw new Error('validation error')
			}
		} catch {
			toggleLoader(false)
			labelRef.current.classList.add('input-error-dark')
		}
	}

	const handleChange = () => {
		labelRef.current?.classList?.remove('input-error-dark')
	}

	return (
		<>
			<div className='mb-4 text-center text-xl font-semibold text-primary-500'>
				Добавить ссылку на соц. сеть
			</div>
			<button
				type='button'
				onClick={handleAccordionClick}
				aria-label='Выбрать соц. сеть'
				ref={accordionBtnRef}
				className='flex flex-row items-center justify-between rounded-xl border
					border-[#E2E2E2] px-4 py-2 text-left'
			>
				<div className='pointer-events-none flex flex-col'>
					<span className='text-xs text-black/60'>
						Выберите соц. сеть
					</span>
					<span className='text-base text-black' id='accordion-text'>
						{socialLinks[0]}
					</span>
				</div>
				<img
					src='/icons/chevron-left.svg'
					width={30}
					height={30}
					alt=''
					id='chevron'
					className='pointer-events-none size-6 -rotate-90 text-primary-500
						transition-transform duration-300'
				/>
			</button>
			<div
				ref={accordionRef}
				style={{
					'--multiplier': 4,
					'--p-y': '14px',
					'--line-h': '24px',
					'--border-between': '1px',
					'--margin': '4px 0px 0px 0px',
				}}
				className='flex h-0 flex-col overflow-hidden rounded-xl shadow
					transition-[height,margin] duration-300'
			>
				{socialLinks.map(item => (
					<button
						key={item}
						type='button'
						onClick={handleSocialButtonClick}
						data-value={item}
						className='border-b border-[#E2E2E2] px-4 py-3.5 text-left text-base
							last:border-none'
					>
						— {item}
					</button>
				))}
			</div>
			<label
				ref={labelRef}
				className='mt-2 flex flex-col rounded-xl border border-[#E2E2E2] px-4 py-2
					text-left transition-colors duration-300'
				htmlFor='link'
			>
				<span className='text-xs text-black/60'>Вставьте ссылку</span>
				<input
					type='text'
					data-target={socialLinks[0]}
					name='link'
					onChange={handleChange}
					className='appearance-none bg-transparent text-base text-black outline-none
						transition-colors duration-300'
				/>
			</label>
			<button
				type='button'
				ref={btnRef}
				onClick={handleSubmit}
				className='mt-2 flex items-center justify-center rounded-xl bg-primary-500 px-4
					py-3.5 text-base font-semibold text-white *:pointer-events-none'
			>
				<span>Добавить</span>
				<Spinner
					className='size-6 rounded-full fill-gray-400 text-white'
					style={{ display: 'none' }}
				/>
			</button>
		</>
	)
}
