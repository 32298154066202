import AuthManager from '../../components/auth/auth-manager'
import Intro from '../../components/auth/intro'

export default function LoginPage() {
	return (
		<section className='relative h-dvh min-h-[640px] text-white'>
			<div
				className='h-full'
				style={{
					paddingTop: 'var(--safe-area-inset-top)',
					paddingBottom: 'var(--safe-area-inset-top)',
				}}
			>
				<div
					className='absolute left-0 top-0 z-0 flex size-full h-dvh min-h-[640px]
						items-center justify-center overflow-hidden bg-primary-500'
				>
					<div
						className='size-full max-w-screen-xs opacity-50 xs:max-w-full sm:max-h-[780px]
							lg:max-w-[1512px]'
					>
						<picture className='inset-0 object-cover' id='bg-image'>
							<source
								media='(min-width: 1024px)'
								srcSet='/images/pc-bg.webp'
							/>
							<source
								media='(min-width: 768px)'
								srcSet='/images/tb-bg.webp'
							/>
							<source
								media='(min-width: 480px)'
								srcSet='/images/bigmb-bg.webp'
							/>
							<img
								src='/images/mb-bg.webp'
								alt='Background'
								className='size-full object-cover'
							/>
						</picture>
					</div>
				</div>
				<div
					className='relative z-10 flex h-full flex-col items-center justify-between p-4
						text-center sm:justify-center sm:p-6 lg:p-12'
				>
					<Intro className='relative top-[15%] sm:top-0 sm:mb-12' />
					<AuthManager />
				</div>
			</div>
		</section>
	)
}
