export default function ContentWrapper({
	children,
	className = '',
	childClass = '',
	style,
}) {
	return (
		<section
			className={`scrollbar-hide safe-content-b safe-content-t w-full min-w-[320px]
				shrink-0 overflow-hidden px-2 2xs:px-4 lg:relative
				lg:w-[calc(60%-192px)] lg:p-6 xl:w-[calc(60%-216px)] xl:p-7
				${className}`}
			style={style}
		>
			<div
				className={`safe-content-nav-b safe-content-nav-t flex size-full flex-col
					${childClass}`}
			>
				{children}
			</div>
		</section>
	)
}
