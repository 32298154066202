/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, memo, Fragment } from 'react'
import { useRevalidator } from 'react-router-dom'
import TaskContent from './task-content'

const DealContent = memo(({ content, userId }) => {
	const revalidator = useRevalidator()

	useEffect(() => {
		const interval = setInterval(() => {
			revalidator.revalidate()
		}, 4500)

		return () => {
			clearInterval(interval)
		}
	}, [revalidator])

	const renderAttachments = useCallback(attachment => {
		const { type, content: attachmentContent } = attachment

		switch (type) {
			case 'task': {
				return <TaskContent {...attachmentContent} />
			}
			default:
				return null
		}
	}, [])

	return content.map(({ attributes: item, id }) => (
		<div
			key={id}
			className={`mt-3 flex w-fit max-w-[90%] flex-col first:mt-0
				${item.senderId === userId ? 'self-end' : 'self-start'}`}
		>
			{item.attachment?.map((attachmentItem, index) => (
				<Fragment key={`attachment-${id}-${index}`}>
					{renderAttachments(attachmentItem)}
				</Fragment>
			))}
			<span
				className={`mt-3 w-fit rounded-[20px] px-3 py-2 first:mt-0
					${item.senderId === userId ? 'self-end bg-primary-500 text-white' : 'self-start bg-gray-100 text-black'}`}
			>
				{item.text}
			</span>
		</div>
	))
})

export default DealContent
