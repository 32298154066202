import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { Outlet, useLoaderData } from 'react-router-dom'
// import { parseMessagesToChats } from '../components/task/utils/chat-processing'
import DealList from '../components/deals/deal-list'
import PathListener from '../components/deals/path-listener'

export default function DealsPage() {
	// const { chats, messages } = useLoaderData() || {
	// 	chats: null,
	// 	messages: null,
	// }

	const { data: chats } = useLoaderData() || { data: [] }
	const user = useAuthUser()

	// const deals = parseMessagesToChats(chats, messages)

	return (
		<section
			className='scrollbar-hide safe-content-t safe-content-b flex w-full min-w-[320px]
				shrink-0 flex-col overflow-hidden px-2 2xs:px-4 lg:relative
				lg:w-[calc(100%-192px)] lg:p-6 xl:w-[calc(100%-216px)] xl:p-7'
		>
			<div className='safe-content-nav-t safe-content-nav-b flex size-full flex-col'>
				<h1
					className='mb-4 hidden w-full bg-white text-2xl font-semibold text-primary-500
						lg:block'
				>
					Ваши сделки
				</h1>
				<div className='flex h-full flex-col lg:flex-row'>
					<DealList
						className='scrollbar-hide w-full shrink-0 lg:w-1/3 lg:overflow-y-scroll lg:pb-12'
						userId={user?.id}
						deals={chats}
						id='deals-list'
					/>
					<PathListener
						listContainerId='deals-list'
						contentContainerId='deal-content'
					/>
					<div
						className={`chat-content scrollbar-hide relative hidden w-full flex-col
							justify-between transition-colors lg:ml-4 lg:h-full lg:min-h-[384px]
							lg:w-2/3 lg:rounded-xl lg:border lg:px-4 lg:pb-4`}
						id='deal-content'
					>
						<div
							className='absolute z-0 flex size-full items-center justify-center'
							id='chat-placeholder'
						>
							<span className='text-lg text-black/50'>
								Выберите чат
							</span>
						</div>
						<Outlet />
					</div>
				</div>
			</div>
		</section>
	)
}
