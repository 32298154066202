/* eslint-disable no-unused-vars */
import { memo, useRef, useState, useCallback } from 'react'
import { socialLinks } from '../../config/variables'
import toggleModal from '../utils/toggle-modal'
import toggleAccordion from '../utils/toggle-accordion'

const SocialInput = memo(
	({
		name,
		setValue = () => {},
		resetError = () => {},
		error,
		errorClass = 'input-error',
		initial = {},
		className = 'border border-[#E2E2E2] text-black caret-black',
	}) => {
		const [values, setValues] = useState(initial)
		const modalRef = useRef(null)

		const handleSocialButtonClick = useCallback(event => {
			const { current } = modalRef
			if (current) {
				const { value } = event.target.dataset

				const accordionText = current.querySelector('#accordion-text')
				if (accordionText) accordionText.textContent = value

				const input = current.querySelector('input')
				if (input) {
					input.value = ''
					input.dataset.key = value
				}

				const accordionBtn = current.querySelector(
					'#social-accordion-btn'
				)
				if (accordionBtn) toggleAccordion({ target: accordionBtn })
			}
		}, [])

		const handleSubmit = useCallback(() => {
			const { current } = modalRef

			if (current) {
				const accordionText = current.querySelector('#accordion-text')
				const input = current.querySelector('input')

				if (accordionText && input) {
					const { textContent: key } = accordionText
					const { value } = input
					setValue(value, key)
					setValues(prev => {
						const arr = { ...prev }
						arr[key] = value
						return arr
					})
				}

				current.classList.toggle('opened')
				document.body.classList.toggle('modal-opened')
			}
		}, [setValue, setValues])

		const handleInputClick = useCallback(
			event => {
				const { current } = modalRef
				if (current) {
					const { key, value } = event.target.dataset

					const accordionText =
						current.querySelector('#accordion-text')
					if (accordionText) accordionText.textContent = key

					const input = current.querySelector('input')
					if (input) {
						input.value = value
						input.dataset.key = key
					}

					resetError(key)
					current.classList.toggle('opened')
					document.body.classList.toggle('modal-opened')
				}
			},
			[resetError]
		)

		const handleDelete = useCallback(() => {
			const { current } = modalRef

			if (current) {
				const accordionText = current.querySelector('#accordion-text')
				const input = current.querySelector('input')

				if (accordionText && input) {
					const { textContent: key } = accordionText
					setValue(null, key)
					setValues(prev => {
						const arr = { ...prev }
						delete arr[key]
						return arr
					})
				}

				current.classList.toggle('opened')
				document.body.classList.toggle('modal-opened')
			}
		}, [setValue, setValues])

		return (
			<>
				<div
					ref={modalRef}
					id='social-form-modal'
					className='scrollbar-hide hidden-opacity fixed left-0 top-0 flex h-dvh min-h-fit
						w-full items-center justify-center overflow-y-scroll [&]:mt-0'
				>
					<button
						type='button'
						data-modal-id='social-form-modal'
						onClick={toggleModal}
						aria-label='Закрыть'
						className='absolute right-0 top-0 size-full bg-black/20'
					/>
					<div
						className='relative z-10 flex w-[calc(100%-16px)] flex-col rounded-xl bg-white
							px-2 py-4 2xs:w-[calc(100%-32px)] 2xs:p-4 lg:w-[480px]'
					>
						<div className='mb-4 text-center text-xl font-semibold text-primary-500'>
							Добавить ссылку на соц. сеть
						</div>
						<button
							type='button'
							onClick={toggleAccordion}
							id='social-accordion-btn'
							data-accordion-id='social-accordion'
							aria-label='Выбрать соц. сеть'
							className='flex flex-row items-center justify-between rounded-xl border
								border-[#E2E2E2] px-4 py-2 text-left'
						>
							<div className='pointer-events-none flex flex-col'>
								<span className='text-xs text-black/60'>
									Выберите соц. сеть
								</span>
								<span
									className='text-base text-black'
									id='accordion-text'
								>
									{socialLinks[0]}
								</span>
							</div>
							<img
								src='/icons/chevron-left.svg'
								width={30}
								height={30}
								alt=''
								id='chevron'
								className='pointer-events-none size-6 -rotate-90 text-primary-500
									transition-transform'
							/>
						</button>
						<div
							id='social-accordion'
							style={{
								'--multiplier': socialLinks.length,
								'--p-y': '14px',
								'--line-h': '24px',
								'--border-between': '1px',
								'--margin': '4px 0px 0px 0px',
							}}
							className='flex h-0 flex-col overflow-hidden rounded-xl shadow
								transition-[height,margin]'
						>
							{socialLinks.map(item => (
								<button
									key={item}
									type='button'
									onClick={handleSocialButtonClick}
									data-value={item}
									className='border-b border-[#E2E2E2] px-4 py-3.5 text-left text-base
										last:border-none'
								>
									— {item}
								</button>
							))}
						</div>
						<div className='mt-2 flex flex-col'>
							<label
								className={`flex flex-col rounded-xl ${className} px-4 py-2 text-left
									transition-colors duration-300`}
								htmlFor={name}
							>
								<span className='text-xs text-inherit opacity-60'>
									Ссылка
								</span>
								<input
									type='text'
									id={name}
									name={name}
									data-key={socialLinks[0]}
									className='appearance-none bg-transparent text-base text-inherit caret-inherit
										outline-none'
								/>
							</label>
						</div>
						<button
							type='button'
							onClick={handleSubmit}
							className='mt-2 flex items-center justify-center rounded-xl bg-primary-500 px-4
								py-3.5 text-base font-semibold text-white *:pointer-events-none'
						>
							Добавить
						</button>
						<button
							type='button'
							onClick={handleDelete}
							className='mt-2 flex items-center justify-center rounded-xl bg-gray-200/50 px-4
								py-3.5 text-base font-semibold text-black *:pointer-events-none'
						>
							Удалить
						</button>
					</div>
				</div>
				{Object.keys(values).map(
					item =>
						values[item] && (
							<div
								key={`input-${item}`}
								className={`flex flex-col ${error?.[item] ? errorClass : ''}`}
							>
								<div
									className={`flex flex-col rounded-xl ${className} px-4 py-2 text-left
									transition-colors duration-300`}
									id='label'
								>
									<span className='text-xs text-inherit opacity-60'>
										Ссылка на {item}
									</span>
									<button
										onClick={handleInputClick}
										data-value={values[item]}
										data-key={item}
										type='button'
										className='appearance-none bg-transparent text-left text-base text-inherit
											caret-inherit outline-none'
									>
										{values[item]}
									</button>
								</div>
								<span
									className='flex h-0 items-end text-sm text-red-500 opacity-0 transition-opacity
										duration-300'
									style={{ transitionProperty: 'opacity, height' }}
									id='error-text'
								>
									{error?.[item]?.message}
								</span>
							</div>
						)
				)}
			</>
		)
	}
)

export default SocialInput
