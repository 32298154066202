import { Outlet, Link } from 'react-router-dom'
import LogoApp from '../../../components/icons/logo-app'

export default function RegisterLayout() {
	return (
		<div className='safe-content-t safe-content-b lg:h-dvh lg:min-h-[1000px]'>
			<section
				className='safe-content-nav-t mt-3 flex flex-col bg-white px-2 pb-6 2xs:px-4
					md:mt-0 lg:h-full lg:items-center lg:justify-center lg:px-6 xl:px-7'
			>
				<Link
					to='/auth/login'
					className='hidden lg:absolute lg:left-6 lg:top-6 lg:mb-0 lg:flex xl:left-7
						xl:top-7'
				>
					<LogoApp className='h-[25px] w-36 text-primary-500 lg:flex xl:h-7 xl:w-40' />
				</Link>
				<div className='safe-content-t fixed left-0 top-0 z-10 flex w-full bg-white'>
					<div
						className='flex h-auto w-full flex-row items-center justify-center px-4 py-3
							text-primary-500 lg:hidden'
					>
						<Link
							to='/auth/login'
							aria-label='Вернуться назад'
							className='absolute left-4'
						>
							<img
								src='/icons/chevron-left.svg'
								width={30}
								alt=''
								height={30}
								className='pointer-events-none size-7'
							/>
						</Link>
						<div
							className='whitespace-nowrap text-lg font-semibold text-primary-500'
							id='page-indicator'
						>
							Регистрация
						</div>
					</div>
				</div>
				<h1
					className='mb-6 hidden text-center text-xl font-semibold text-primary-500
						lg:block'
				>
					Регистрация
				</h1>
				<Outlet />
			</section>
		</div>
	)
}
