/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import useSignIn from 'react-auth-kit/hooks/useSignIn'
import useLoader from '../../utils/use-loader'

import Spinner from '../../icons/spinner'
import TextInput from '../../form/text-input'
import PasswordInput from './password-input'

const schema = z.object({
	email: z
		.string()
		.min(1, 'Обязательное поле')
		.email('Введите корректную электронную почту'),
	password: z.string().min(5, 'Минимальное количество символов - 5'),
})

export default function LoginForm({ className = '' }) {
	const signIn = useSignIn()
	const navigate = useNavigate()

	const { btnRef, toggleLoader } = useLoader()

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
	})

	const onSubmit = useCallback(
		async values => {
			toggleLoader(true)

			try {
				const req = await fetch(
					'https://api.flowork.ru/api/auth/local',
					{
						method: 'POST',
						headers: { 'Content-Type': 'Application/json' },
						body: JSON.stringify({
							identifier: values.email.toLowerCase(),
							password: values.password,
						}),
					}
				)

				const res = await req.json()

				if (res.error)
					throw new Error('Неверная электронная или пароль')

				const userReq = await fetch(
					`https://api.flowork.ru/api/users/${res.user.id}?populate[profile]=*`
				)
				const { profile: userProfile } = await userReq.json()

				if (
					signIn({
						auth: {
							token: res.jwt,
							type: 'Bearer',
						},
						userState: {
							id: res.user.id,
							email: res.user.email,
							type: userProfile.type,
							name: userProfile.name,
							surname: userProfile.surname,
						},
					})
				) {
					navigate('/feed')
				} else {
					throw new Error('Неверная электронная или пароль')
				}
			} catch (error) {
				setError('email', {
					type: 'manual',
					message: error.message,
				})
				setValue('password', '')
			}

			toggleLoader(false)
		},
		[navigate, signIn, toggleLoader, setError, setValue]
	)

	return (
		<form
			className={`-mt-2 flex flex-col *:mt-2 ${className}`}
			onSubmit={handleSubmit(onSubmit)}
		>
			<TextInput
				inputProps={register('email')}
				label='Электронная почта'
				errorClass='input-error-dark'
				error={errors?.email}
				className='border border-[#E2E2E2]/70 bg-white/5 text-white caret-white'
			/>
			<TextInput
				label='Пароль'
				errorClass='input-error-dark'
				error={errors?.password}
				className='border border-[#E2E2E2]/70 bg-white/5 text-white caret-white'
			>
				<PasswordInput
					name='password'
					id='password'
					{...register('password')}
					className='appearance-none bg-transparent text-base text-inherit outline-none
						transition-colors duration-300'
				/>
			</TextInput>
			<button
				type='submit'
				aria-label='Войти'
				ref={btnRef}
				className='flex flex-row items-center justify-center rounded-xl bg-white p-4
					text-center text-base font-semibold text-primary-500
					*:pointer-events-none'
			>
				<span>Войти</span>
				<Spinner
					className='size-6 rounded-full fill-primary-500 text-gray-200'
					style={{ display: 'none' }}
				/>
			</button>
		</form>
	)
}
