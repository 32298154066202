import { Link } from 'react-router-dom'

export default function DealPreview({
	user,
	className,
	itemId,
	linkState,
}) {
	const avatar = user.avatar?.data?.attributes

	return (
		<Link
			to={`/deals/${itemId}`}
			state={linkState}
			data-id={itemId}
			className={`flex flex-row items-center rounded-xl border border-primary-500/10 p-4
				text-left transition-colors *:pointer-events-none ${className}`}
		>
			<img
				src={
					avatar
						? `https:api.flowork.ru${avatar.url}`
						: '/images/default-profile.webp'
				}
				alt={`Аватар ${user.profile?.name}`}
				loading='lazy'
				className='size-10 shrink-0 rounded-full object-cover'
			/>
			<div className='ml-2 flex flex-col'>
				<span className='text-sm font-semibold text-black'>
					{user.business?.name ||
						`${user.profile?.name} ${user.profile?.surname}`}
				</span>
				<span className='text-sm text-black/60'>
					{user.business?.category || 'Блогер'}
				</span>
			</div>
		</Link>
	)
}
