import { API_URL, usersChat } from '../../../config/requests'

export async function getChat(senderId, receiverId, authHeader) {
	try {
		const chatReq = await fetch(usersChat(senderId, receiverId))
		const { data: chatRes } = (await chatReq.json()) || { data: [] }

		let chatId

		if (chatRes.length > 0) {
			chatId = chatRes[0].id
		} else {
			const createChatReq = await fetch(`${API_URL}/chats`, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: authHeader,
				},
				body: JSON.stringify({
					data: {
						users: [senderId, receiverId],
					},
				}),
			})

			const { data: createChatRes } = await createChatReq.json()
			chatId = createChatRes.id
		}

		return chatId
	} catch {
		return -1
	}
}

export async function sendMessage(content, authHeader) {
	try {
		const createMessageReq = await fetch(`${API_URL}/messages`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				Authorization: authHeader,
			},
			body: JSON.stringify({
				data: {
					time: new Date().toISOString(),
					readed: false,
					...content,
				},
			}),
		})

		return createMessageReq.status === 200
	} catch {
		return false
	}
}

export function parseMessagesToChats(initialChats, messages) {
	const chats = [...initialChats]

	chats.forEach(chat => {
		// eslint-disable-next-line no-param-reassign
		chat.messages = messages.filter(
			item => item.attributes.chat.data.id === chat.id
		)
	})

	return chats
}
