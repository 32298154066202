import { useRef } from 'react'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import { useRevalidator } from 'react-router-dom'
import useLoader from '../utils/use-loader'
import Spinner from '../icons/spinner'
import { sendMessage } from '../task/utils/chat-processing'

export default function MessageInput({ className, chatId, userId }) {
	const textAreaRef = useRef(null)

	const authHeader = useAuthHeader()
	const revalidator = useRevalidator()

	const { btnRef, toggleLoader } = useLoader()

	// max stroke count * line-height + initial height
	const maxRows = 3

	const handleChange = event => {
		const { target } = event
		const { scrollHeight } = target

		target.classList.remove('input-error')

		if (scrollHeight <= maxRows * 24 + 16) {
			target.style.height = 'auto'
			target.style.height = `${target.scrollHeight}px`
		}
	}

	const handleError = () => {
		textAreaRef.current?.classList?.add('input-error')
	}

	const handleClick = async () => {
		const textArea = textAreaRef.current
		toggleLoader(true)
		if (textArea) {
			const { value } = textArea

			if (!value) {
				handleError()
			} else {
				const isSended = await sendMessage(
					{
						readed: false,
						senderId: userId,
						text: value,
						chat: chatId,
					},
					authHeader
				)

				if (isSended) {
					revalidator.revalidate()
					textArea.value = ''
				} else {
					handleError()
				}

				toggleLoader(false)
			}
		}
	}

	return (
		<div className={`flex flex-row items-end ${className}`}>
			<textarea
				ref={textAreaRef}
				onChange={handleChange}
				rows={1}
				placeholder='Напиши мне'
				className='scrollbar-hide h-[40px] w-full resize-none appearance-none
					rounded-[20px] border border-primary-500/10 px-4 py-2 text-base
					text-black outline-none transition-colors focus:border-primary-500/40
					focus:bg-white'
			/>
			<button
				type='button'
				onClick={handleClick}
				ref={btnRef}
				aria-label='Отправить сообщения'
				className='ml-2 shrink-0 rounded-full bg-primary-500 p-2 *:pointer-events-none'
			>
				<span>
					<img
						src='/icons/send.svg'
						width={30}
						height={30}
						alt='Отправить'
						className='size-6'
					/>
				</span>
				<Spinner
					className='size-6 rounded-full fill-gray-400 text-white'
					style={{ display: 'none' }}
				/>
			</button>
		</div>
	)
}
