export const API_URL = 'https://api.flowork.ru/api'

export const feed = userType =>
	`${API_URL}/tasks?populate[user][populate][avatar][fields][0]=url&populate[user][populate][profile]=*&populate[user][populate][business]=*&populate[user][populate][blogger]=*&pagination[limit]=5&filters[user][profile][type][$not]=${userType}`

export const userInfo = userId =>
	`${API_URL}/users/${userId}?populate[avatar][fields][0]=url&populate[profile]=*&populate[business]=*&populate[blogger]=*&populate[socialLinks]=*`

export const userSocial = userId =>
	`${API_URL}/users/${userId}?populate[socialLinks]=*`

export const tasksUser = userId =>
	`${API_URL}/tasks?filters[user][id][$eq]=${userId}&pagination[limit]=5`

export const userDeals = userId =>
	`${API_URL}/chats?filters[users][$in]=${userId}&populate[users][populate][profile]=*&populate[users][populate][business]=*&populate[users][populate][avatar][fields][0]=url`

export const messages = chats =>
	`${API_URL}/messages?${chats.map(item => `filters[chat][$eq]=${item.id}`).join('&')}&sort=time:desc&populate[chat]=id`

export const usersChat = (senderId, receiverId) =>
	`${API_URL}/chats?filters[$and][0][users][$in]=${senderId}&filters[users][$in]=${receiverId}`

export const deal = dealId =>
	`${API_URL}/messages?filters[chat][$eq]=${dealId}&sort=time:asc`
