import { Link, Outlet, ScrollRestoration } from 'react-router-dom'

import * as Icons from './icons/navigation'
import LogoApp from './icons/logo-app'

import PageIndicator from './page-indicator'

import PathListener from './utils/path-listener'
import toggleModal from './utils/toggle-modal'
import SubscribeModal from './modal/subscribe-modal'

export default function Layout() {
	return (
		<main className='flex flex-col lg:h-dvh lg:min-h-[496px] lg:flex-row'>
			<header
				className='scrollbar-hide safe-content-b fixed bottom-0 z-10 flex w-full
					min-w-[320px] shrink-0 flex-col overflow-x-scroll border-t
					border-gray-200 bg-white pt-3 text-primary-500 2xs:px-4 lg:relative
					lg:w-fit lg:min-w-fit lg:border-none lg:bg-primary-500 lg:p-6
					lg:text-white xl:p-7'
			>
				<PathListener />
				<LogoApp className='hidden h-7 w-36 lg:mb-8 lg:flex xl:h-8 xl:w-40' />
				<nav>
					<ul
						className='flex flex-row justify-between pb-3 text-xs xs:text-sm lg:flex-col
							lg:justify-start lg:pb-0 lg:text-base'
					>
						<li className='w-1/5'>
							<Link
								to='feed'
								className='nav-link flex flex-col items-center lg:flex-row'
							>
								<Icons.Feed className='preview-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								<Icons.FeedFill className='active-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								Лента
							</Link>
						</li>
						<li className='w-1/5'>
							<Link
								to='deals'
								className='nav-link flex flex-col items-center lg:mt-6 lg:flex-row'
							>
								<Icons.Message className='preview-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								<Icons.MessageFill className='active-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								Сделки
							</Link>
						</li>
						<li className='w-1/5'>
							<Link
								to='favourites'
								className='nav-link flex flex-col items-center lg:mt-6 lg:flex-row'
							>
								<Icons.Favourite className='preview-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								<Icons.FavouriteFill className='active-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								Избранное
							</Link>
						</li>
						<li className='w-1/5'>
							<Link
								to='create'
								className='nav-link flex flex-col items-center lg:mt-6 lg:flex-row'
							>
								<Icons.Add className='preview-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								<Icons.AddFill className='active-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								Создать
							</Link>
						</li>
						<li className='w-1/5'>
							<Link
								to='profile'
								className='nav-link flex flex-col items-center lg:mt-6 lg:flex-row'
							>
								<Icons.Profile className='preview-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								<Icons.ProfileFill className='active-icon mb-0.5 size-7 shrink-0 stroke-2 lg:mb-0 lg:mr-2.5' />
								Профиль
							</Link>
						</li>
					</ul>
				</nav>
			</header>
			<PageIndicator />
			<Outlet />
			<ScrollRestoration />
			<div
				id='subscribe-modal'
				className='scrollbar-hide hidden-opacity fixed left-0 top-0 flex h-dvh min-h-fit
					w-full items-center justify-center overflow-y-scroll'
			>
				<button
					type='button'
					aria-label='Закрыть'
					data-modal-id='subscribe-modal'
					onClick={toggleModal}
					className='absolute right-0 top-0 size-full bg-black/20'
				/>
				<SubscribeModal
					className='relative z-10 flex w-[calc(100%-16px)] flex-col rounded-xl px-2 py-4
						2xs:w-[calc(100%-32px)] 2xs:p-6 lg:w-[480px]'
				/>
			</div>
		</main>
	)
}
