/* eslint-disable no-unused-vars */
import { useRef, useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import useLoader from '../utils/use-loader'
import Spinner from '../icons/spinner'

export default function CreateTaskForm() {
	const authHeader = useAuthHeader()
	const user = useAuthUser()

	const formRef = useRef(null)
	const { btnRef, toggleLoader } = useLoader()

	const initialValues = useMemo(
		() => ({
			description: '',
			price: 0,
		}),
		[]
	)

	const validationSchema = useMemo(
		() =>
			Yup.object({
				description: Yup.string().min(5).max(160).required(),
				price: Yup.number(),
			}),
		[]
	)

	const onSubmit = useCallback(
		values => {
			toggleLoader(true)

			fetch('https://api.flowork.ru/api/tasks', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: authHeader,
				},
				body: JSON.stringify({
					data: {
						user: user.id,
						price: values.price || null,
						shortDescription: values.description,
					},
				}),
			})
				.then(res => res.json())
				.then(res => {
					if (res.error) {
						throw new Error('Request error')
					} else {
						toggleLoader(false)
						btnRef.current.querySelector('span').textContent =
							'Успешно'
					}
				})
				.catch(() => {
					btnRef.current?.classList?.add('btn-error')
					toggleLoader(false)
				})
		},
		[toggleLoader, btnRef, user, authHeader]
	)

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
		validateOnMount: true,
	})

	const { handleChange, handleBlur, errors, setValues } = formik

	const handleInputChange = useCallback(
		event => {
			const { target } = event
			formRef.current
				?.querySelector(`label[for="${target.name}"]`)
				?.classList?.remove('input-error-dark')
			handleChange(event)
		},
		[handleChange]
	)

	const handleInputBlur = useCallback(
		event => {
			handleBlur(event)
			const { target } = event
			formRef.current
				?.querySelector(`label[for="${target.name}"]`)
				?.classList?.toggle('input-error-dark', !!errors[target.name])
		},
		[handleBlur, errors]
	)

	const handleSubmit = useCallback(
		event => {
			const errorsKeys = Object.keys(errors)
			if (errorsKeys.length > 0) {
				event.preventDefault()
				errorsKeys.forEach(item => {
					formRef.current
						?.querySelector(`label[for="${item}"]`)
						?.classList?.add('input-error-dark')
				})
			}
		},
		[errors]
	)

	return (
		<form
			className='flex flex-col'
			onSubmit={formik.handleSubmit}
			ref={formRef}
		>
			<label
				className='flex flex-col rounded-xl border border-[#E2E2E2] px-4 py-2 text-left
					transition-colors'
				htmlFor='description'
			>
				<span className='text-xs text-black/60'>Описание</span>
				<input
					type='text'
					name='description'
					id='description'
					onChange={handleInputChange}
					onBlur={handleInputBlur}
					className='appearance-none bg-transparent text-base text-black outline-none
						transition-colors'
				/>
			</label>
			<label
				className='mt-2 flex flex-col rounded-xl border border-[#E2E2E2] px-4 py-2
					text-left transition-colors'
				htmlFor='price'
			>
				<span className='text-xs text-black/60'>Цена</span>
				<input
					type='text'
					name='price'
					id='price'
					onChange={handleInputChange}
					onBlur={handleInputBlur}
					placeholder='Договорная'
					className='appearance-none bg-transparent text-base text-black outline-none
						transition-colors'
				/>
			</label>
			<button
				ref={btnRef}
				onClick={handleSubmit}
				type='submit'
				aria-label='Создать оффер'
				className='mt-2 rounded-xl bg-gray-200/50 px-4 py-3.5 font-semibold text-black'
			>
				<span>Отправить</span>
				<Spinner
					className='size-6 rounded-full fill-gray-400 text-white'
					style={{ display: 'none' }}
				/>
			</button>
		</form>
	)
}
