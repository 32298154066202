export default function toggleAccordion(event) {
	const { target } = event
	const accordion = document.getElementById(
		target.dataset.accordionId
	)

	if (accordion) {
		accordion.classList.toggle('accordion-open')
		target.querySelector('#chevron')?.classList?.toggle('rotate-90')
	}
}
