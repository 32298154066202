/* eslint-disable jsx-a11y/label-has-associated-control */

import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { z } from 'zod'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import ReactInputMask from 'react-input-mask'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import useLoader from '../../utils/use-loader'

import Spinner from '../../icons/spinner'

import PasswordInput from './password-input'
import PolicyLabel from './policy-label'
import TextInput from '../../form/text-input'
import SelectInput from '../../form/select-input'
import { blogThemes, socialLinks } from '../../../config/variables'
import SocialInput from '../../form/social-input'
import toggleModal from '../../utils/toggle-modal'

const schema = z.object({
	name: z.string().min(1, 'Обязательное поле'),
	surname: z.string().min(1, 'Обязательное поле'),
	phone: z.string().min(18, 'Введите номер полностью'),
	email: z
		.string()
		.min(1, 'Обязательное поле')
		.email('Введите корректную электронную почту'),
	username: z.string().min(1, 'Обязательное поле'),
	description: z
		.string()
		.min(10, 'Минимальное количество символов - 10')
		.max(160, 'Максимальное количество символов - 160'),
	password: z.string().min(5, 'Минимальное количество символов - 5'),
	blogTheme: z.string().min(1, 'Обязательное поле'),
	socLinks: z.object(
		socialLinks.reduce((acc, key) => {
			acc[key] = z
				.string()
				.url('Введите корректную ссылку')
				.nullable()
			return acc
		}, {})
	),
})

export default function BlogerRegisterForm({ className }) {
	const signIn = useSignIn()
	const navigate = useNavigate()

	const { btnRef, toggleLoader } = useLoader()
	const formRef = useRef(null)

	const {
		register,
		handleSubmit,
		setValue,
		clearErrors,
		setError,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			blogTheme: '',
			socLinks: socialLinks.reduce((acc, key) => {
				acc[key] = null
				return acc
			}, {}),
		},
	})

	const onSubmit = useCallback(
		async values => {
			toggleLoader(true)

			const email = values.email.toLowerCase()

			const findReq = await fetch(
				`https://api.flowork.ru/api/users?filters[email][$eq]=${email}`
			)
			const findRes = await findReq.json()

			if (findRes && findRes.length > 0) {
				setError('email', {
					type: 'manual',
					message: 'Данная почта уже зарегистрирована',
				})

				toggleLoader(false)

				return null
			}

			const {
				name,
				surname,
				blogTheme,
				socLinks,
				phone,
				description,
				...rest
			} = values

			const socialLinksArr = Object.entries(socLinks).map(
				([key, value]) => ({
					label: key,
					link: value,
				})
			)

			try {
				const registerReq = await fetch(
					'https://api.flowork.ru/api/auth/local/register',
					{
						method: 'POST',
						headers: { 'Content-type': 'application/json' },
						body: JSON.stringify({
							profile: {
								name,
								surname,
								email,
								phone,
								description,
								type: 'blogger',
							},
							socialLinks: socialLinksArr,
							...rest,
						}),
					}
				)

				const registerRes = await registerReq.json()

				toggleLoader(false)

				if (
					signIn({
						auth: { token: registerRes.jwt, type: 'Bearer' },
						userState: {
							id: registerRes.user.id,
							email: registerRes.user.email,
							type: 'blogger',
							name: values.name,
							surname: values.surname,
						},
					})
				)
					navigate('/feed')
				else navigate('/auth/local')

				return null
			} catch {
				btnRef.current?.classList?.add('btn-error')
				toggleLoader(false)
				return null
			}
		},
		[toggleLoader, navigate, btnRef, signIn, setError]
	)

	return (
		<form
			ref={formRef}
			onSubmit={handleSubmit(onSubmit)}
			className={`-mt-2 flex flex-col *:mt-2 ${className}`}
			style={{ '--error-height': '1.5rem' }}
		>
			<TextInput
				inputProps={register('name')}
				label='Имя'
				error={errors?.name}
			/>
			<TextInput
				inputProps={register('surname')}
				label='Фамилия'
				error={errors?.surname}
			/>
			<div className='text-sm text-black/60 [&]:mt-1'>
				Данные должны совпадать с вашей реальной личностью
			</div>
			<TextInput
				inputProps={register('email')}
				label='Электронная почта'
				error={errors?.email}
			/>
			<TextInput
				inputProps={register('username')}
				label='Логин'
				error={errors?.username}
			/>
			<TextInput label='Пароль' error={errors?.password}>
				<PasswordInput
					isDark
					name='password'
					id='password'
					{...register('password')}
					className='appearance-none bg-transparent text-base text-black outline-none
						transition-colors duration-300'
				/>
			</TextInput>
			<TextInput label='Телефон' error={errors?.phone}>
				<ReactInputMask
					maskChar=''
					name='phone'
					id='phone'
					type='tel'
					mask='+7 (999) 999-99-99'
					alwaysShowMask={false}
					{...register('phone')}
					className='appearance-none bg-transparent text-base text-black outline-none
						transition-colors duration-300'
				/>
			</TextInput>
			<TextInput
				inputProps={register('description')}
				label='Описание (до 160 символов)'
				error={errors?.description}
			/>
			<SelectInput
				label='Тематика блога'
				error={errors?.blogTheme}
				values={blogThemes}
				setValue={value => {
					setValue('blogTheme', value)
				}}
				resetError={() => {
					clearErrors('blogTheme')
				}}
			/>
			<SocialInput
				name='social'
				initial={socialLinks.reduce((acc, key) => {
					acc[key] = ''
					return acc
				}, {})}
				error={errors?.socLinks}
				setValue={(value, key) => {
					setValue(`socLinks.${key}`, value)
				}}
				resetError={key => {
					clearErrors(`socLinks.${key}`)
				}}
			/>
			<button
				type='button'
				onClick={toggleModal}
				data-modal-id='social-form-modal'
				className='rounded-xl bg-primary-500/20 px-4 py-3.5 text-center font-semibold
					text-primary-500'
			>
				Добавить ссылку на соц. сеть
			</button>
			<button
				aria-label='Зарегистрироваться'
				type='submit'
				ref={btnRef}
				onClick={handleSubmit}
				className='flex w-full flex-row items-center justify-center rounded-xl
					bg-primary-500 p-4 text-base font-semibold text-white
					*:pointer-events-none'
			>
				<span>Зарегистрироваться</span>
				<Spinner
					className='size-6 rounded-full fill-gray-400 text-white'
					style={{ display: 'none' }}
				/>
			</button>
			<PolicyLabel />
		</form>
	)
}
