import toggleModal from '../utils/toggle-modal'

export default function SubscribeModal({ className }) {
	return (
		<div
			className={`relative bg-primary-500 text-white ${className}`}
		>
			<button
				type='button'
				className='absolute right-3 top-3 *:pointer-events-none'
				onClick={toggleModal}
				aria-label='Закрыть'
				data-modal-id='subscribe-modal'
			>
				<img
					src='/icons/close.svg'
					width={30}
					height={30}
					alt=''
					className='size-8'
				/>
			</button>
			<span className='text-4xl font-semibold'>
				Подключите Flowork&nbsp;PRO
			</span>
			<div className='my-8 flex flex-col'>
				<div
					className='mt-4 flex flex-row items-start text-lg font-semibold leading-tight
						first:mt-0'
				>
					<img
						src='/icons/eye.svg'
						width={30}
						height={30}
						alt=''
						className='mr-2 size-6'
					/>
					<span>Просмотр офферов конкурентов</span>
				</div>
				<div
					className='mt-4 flex flex-row items-start text-lg font-semibold leading-tight
						first:mt-0'
				>
					<img
						src='/icons/infinity.svg'
						width={30}
						height={30}
						alt=''
						className='mr-2 size-6'
					/>
					<span>Безлимитное отображение офферов</span>
				</div>
				<div
					className='mt-4 flex flex-row items-start text-lg font-semibold leading-tight
						first:mt-0'
				>
					<img
						src='/icons/arrow-back.svg'
						width={30}
						height={30}
						alt=''
						className='mr-2 size-6'
					/>
					<span>Оставляйте отзывы</span>
				</div>
				<div
					className='mt-4 flex flex-row items-start text-lg font-semibold leading-tight
						first:mt-0'
				>
					<img
						src='/icons/star.svg'
						width={30}
						height={30}
						alt=''
						className='mr-2 size-6'
					/>
					<span>Проходите верификацию</span>
				</div>
				<div
					className='mt-4 flex flex-row items-start text-lg font-semibold leading-tight
						first:mt-0'
				>
					<img
						src='/icons/bolt.svg'
						width={30}
						height={30}
						alt=''
						className='mr-2 size-6'
					/>
					<span>Получайте бонусы от Flowork</span>
				</div>
			</div>
			<div className='flex flex-col items-center justify-center text-center'>
				<span className='opacity-60'>
					Стоимость подписки
					<br />
					1490₽ в&nbsp;месяц
				</span>
				<button
					type='button'
					data-modal-id='subscribe-modal'
					onClick={toggleModal}
					className='mt-4 rounded-full bg-white px-4 py-2.5 font-semibold text-black'
				>
					Подключить
				</button>
				<button
					type='button'
					onClick={toggleModal}
					data-modal-id='subscribe-modal'
					className='decoration-skip-ink-none mt-4 text-white underline decoration-1
						underline-offset-2 opacity-60'
				>
					Не&nbsp;интересно
				</button>
			</div>
		</div>
	)
}
