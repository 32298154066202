import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { z } from 'zod'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import ReactInputMask from 'react-input-mask'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, Controller } from 'react-hook-form'

import { AddressSuggestions } from 'react-dadata'
import useLoader from '../../utils/use-loader'

import Spinner from '../../icons/spinner'

import PasswordInput from './password-input'
import PolicyLabel from './policy-label'
import TextInput from '../../form/text-input'

const schema = z.object({
	name: z.string().min(1, 'Обязательное поле'),
	surname: z.string().min(1, 'Обязательное поле'),
	phone: z.string().min(18, 'Введите номер полностью'),
	email: z
		.string()
		.min(1, 'Обязательное поле')
		.email('Введите корректную электронную почту'),
	username: z.string().min(1, 'Обязательное поле'),
	description: z
		.string()
		.min(10, 'Минимальное количество символов - 10')
		.max(160, 'Максимальное количество символов - 160'),
	password: z.string().min(5, 'Минимальное количество символов - 5'),
	companyAddress: z.string().min(1, 'Выберите адрес из предложенных'),
	companyBrand: z.string().min(1, 'Обязательное поле'),
	companyPerson: z.string().min(1, 'Обязательное поле'),
})

export default function BlogerRegisterForm({ className }) {
	const signIn = useSignIn()
	const navigate = useNavigate()

	const { btnRef, toggleLoader } = useLoader()
	const formRef = useRef(null)

	const {
		register,
		handleSubmit,
		setError,
		control,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
	})

	const onSubmit = useCallback(
		async values => {
			toggleLoader(true)

			const email = values.email.toLowerCase()

			const findReq = await fetch(
				`https://api.flowork.ru/api/users?filters[email][$eq]=${email}`
			)
			const findRes = await findReq.json()

			if (findRes && findRes.length > 0) {
				setError('email', {
					type: 'manual',
					message: 'Данная почта уже зарегистрирована',
				})

				toggleLoader(false)

				return null
			}

			const {
				name,
				surname,
				phone,
				description,
				companyAddress,
				companyBrand,
				companyPerson,
				...rest
			} = values

			let coords
			try {
				const coordsReq = await fetch(
					'https://server.flowork.ru/api/address',
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json',
						},
						body: JSON.stringify({
							address: companyAddress.replace(/^г\s*/, ''),
						}),
					}
				)

				const coordsRes = await coordsReq.json()
				coords = coordsRes.join(', ')
			} catch {
				coords = ''
			}

			try {
				const registerReq = await fetch(
					'https://api.flowork.ru/api/auth/local/register',
					{
						method: 'POST',
						headers: { 'Content-type': 'application/json' },
						body: JSON.stringify({
							profile: {
								name,
								surname,
								email,
								description,
								type: 'business',
								phone,
								coords,
								address: companyAddress,
							},
							business: {
								name: companyBrand,
								address: companyAddress,
								personName: companyPerson,
							},
							...rest,
						}),
					}
				)

				const registerRes = await registerReq.json()

				toggleLoader(false)

				if (
					signIn({
						auth: { token: registerRes.jwt, type: 'Bearer' },
						userState: {
							id: registerRes.user.id,
							email: registerRes.user.email,
							type: 'business',
							name: values.name,
							surname: values.surname,
						},
					})
				)
					navigate('/feed')
				else navigate('/auth/local')

				return null
			} catch {
				btnRef.current?.classList?.add('btn-error')
				toggleLoader(false)
				return null
			}
		},
		[toggleLoader, navigate, btnRef, signIn, setError],
		[]
	)

	// TODD: do something with that
	// При переключении на enter первым при пустых полях выделяется это почему-то
	const {
		onChange: phoneOnChange,
		onBlur: phoneOnBlur,
		name: phoneName,
		ref: phoneRef,
	} = register('phone')

	return (
		<form
			ref={formRef}
			onSubmit={handleSubmit(onSubmit)}
			className={`-mt-2 flex flex-col *:mt-2 ${className}`}
			style={{ '--error-height': '1.5rem' }}
		>
			<TextInput
				inputProps={register('name')}
				label='Имя'
				error={errors?.name}
			/>
			<TextInput
				inputProps={register('surname')}
				label='Фамилия'
				error={errors?.surname}
			/>
			<div className='text-sm text-black/60 [&]:mt-1'>
				Данные должны совпадать с вашей реальной личностью
			</div>
			<TextInput
				inputProps={register('email')}
				label='Электронная почта'
				error={errors?.email}
			/>
			<TextInput
				inputProps={register('username')}
				label='Логин'
				error={errors?.username}
			/>
			<TextInput label='Пароль' error={errors?.password}>
				<PasswordInput
					isDark
					name='password'
					id='password'
					{...register('password')}
					className='appearance-none bg-transparent text-base text-inherit outline-none
						transition-colors duration-300'
				/>
			</TextInput>
			<TextInput label='Телефон' error={errors?.phone}>
				<ReactInputMask
					maskChar=''
					id='phone'
					type='text'
					mask='+7 (999) 999-99-99'
					alwaysShowMask={false}
					inputRef={phoneRef}
					onChange={phoneOnChange}
					name={phoneName}
					onBlur={phoneOnBlur}
					className='appearance-none bg-transparent text-base text-inherit outline-none
						transition-colors duration-300'
				/>
			</TextInput>
			<TextInput
				inputProps={register('description')}
				label='Описание (до 160 символов)'
				error={errors?.description}
			/>
			<TextInput
				inputProps={register('companyBrand')}
				label='Название компании-бренда'
				error={errors?.companyBrand}
			/>
			<TextInput
				inputProps={register('companyPerson')}
				label='Наименование юридического лица'
				error={errors?.companyPerson}
			/>
			<TextInput label='Адрес' error={errors?.companyAddress}>
				<Controller
					name='companyAddress'
					control={control}
					defaultValue=''
					render={({ field }) => (
						<AddressSuggestions
							token='3ffbf532781a9e91fc30bbf5a3c43d0fc96c420a'
							inputProps={{
								className: 'w-full appearance-none outline-none',
							}}
							onChange={suggestion =>
								field.onChange(suggestion.value)
							}
							ref={register('companyAddress').ref}
							count={3}
							minChars={5}
							containerClassName='relative'
							suggestionsClassName='flex flex-col absolute mb-3 top-[calc(100%+0.75rem)] w-[calc(100%+2rem)] -left-4 bg-white rounded-xl shadow'
							suggestionClassName='text-left border-b border-[#E2E2E2] px-4 py-3.5 text-left text-base
											last:border-none'
							highlightClassName=''
						/>
					)}
				/>
			</TextInput>
			<button
				aria-label='Зарегистрироваться'
				type='submit'
				ref={btnRef}
				onClick={handleSubmit}
				className='flex w-full flex-row items-center justify-center rounded-xl
					bg-primary-500 p-4 text-base font-semibold text-white
					*:pointer-events-none'
			>
				<span>Зарегистрироваться</span>
				<Spinner
					className='size-6 rounded-full fill-gray-400 text-white'
					style={{ display: 'none' }}
				/>
			</button>
			<PolicyLabel />
		</form>
	)
}
