/* eslint-disable no-unused-vars */
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useEffect } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import ContentWrapper from '../components/content-wrapper'
import UserInfoCard from '../components/task/user-info-card'
import TasksList from '../components/task/task-list'
import MapComponent from '../components/task/map'

export default function UserFeedPage() {
	const { userInfo, userTasks } = useLoaderData()
	const user = useAuthUser()

	useEffect(() => {
		const pageIndicator = document.getElementById('page-indicator')

		if (pageIndicator)
			pageIndicator.textContent =
				userInfo.business?.name || userInfo.profile.name
	}, [userInfo])

	const isBusiness = user.type === 'business'

	return (
		<>
			<ContentWrapper
				className={
					isBusiness
						? '[&]:lg:w-[calc(100%-192px)] [&]:xl:w-[calc(100%-216px)]'
						: ''
				}
			>
				<h1 className='mb-4 hidden text-2xl font-semibold text-primary-500 lg:block'>
					<Link to='/feed' className='opacity-50'>
						Все офферы
					</Link>{' '}
					{'>'} {userInfo.business?.name || userInfo.profile.name}
				</h1>
				<h2 className='mb-4 text-2xl font-semibold text-primary-500 lg:hidden'>
					О {isBusiness ? 'Блогере' : 'Компании'}
				</h2>
				<UserInfoCard userInfo={userInfo} isBusiness={isBusiness} />
				<h2 className='my-4 text-2xl font-semibold text-primary-500'>
					Офферы
				</h2>
				<TasksList
					items={userTasks.data}
					user={userInfo}
					authUser={user}
				/>
			</ContentWrapper>
			{!isBusiness && (
				<MapComponent
					className='order-1 hidden w-full lg:order-2 lg:block lg:w-2/5'
					items={userTasks.data}
					user={userInfo}
				/>
			)}
		</>
	)
}
