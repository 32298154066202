import { memo } from 'react'

const TextInput = memo(
	({
		name,
		label,
		error,
		inputProps,
		children,
		errorClass = 'input-error',
		className = 'border border-[#E2E2E2] text-black caret-black',
	}) => (
		<div className={`flex flex-col ${error ? errorClass : ''}`}>
			<label
				className={`flex flex-col rounded-xl ${className} px-4 py-2 text-left
					transition-colors duration-300`}
				htmlFor={name}
			>
				<span className='text-xs text-inherit opacity-60'>
					{label}
				</span>
				{children || (
					<input
						type='text'
						id={name}
						name={name}
						className='appearance-none bg-transparent text-base text-inherit caret-inherit
							outline-none'
						{...inputProps}
					/>
				)}
			</label>
			<span
				className='flex h-0 items-end text-sm text-red-500 opacity-0 transition-opacity
					duration-300'
				style={{ transitionProperty: 'opacity, height' }}
				id='error-text'
			>
				{error?.message}
			</span>
		</div>
	)
)

export default TextInput
