import {
	useContext,
	useCallback,
	createContext,
	useState,
	useRef,
	useEffect,
} from 'react'
import { Outlet, useRevalidator } from 'react-router-dom'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'

import InputForm from '../components/profile/input-form'
import RestrictedField from '../components/profile/restricted-field'
import DeleteAccountForm from '../components/profile/delete-account-form'
import AddSocialForm from '../components/profile/add-social-form'

const SetInputModalContext = createContext(null)

export default function ProfileLayout() {
	const [inputObj, setInputObj] = useState(null)

	const wrapperRef = useRef(null)

	const authHeader = useAuthHeader()
	const revalidator = useRevalidator()
	const { id: userId } = useAuthUser() || { id: -1 }

	const handleClose = useCallback(() => {
		wrapperRef.current.classList.remove('opened')
		setTimeout(() => {
			setInputObj(null)
		}, 150)
	}, [setInputObj])

	useEffect(() => {
		const isOpened = !!inputObj
		wrapperRef.current.classList.toggle('opened', isOpened)
		document.body.classList.toggle('overflow-hidden', isOpened)
	}, [inputObj])

	const renderElement = () => {
		switch (inputObj?.modal) {
			case 'input': {
				return (
					<InputForm
						authHeader={authHeader}
						data={inputObj}
						handleClose={handleClose}
						revalidator={revalidator}
						userId={userId}
					/>
				)
			}
			case 'warning': {
				return <RestrictedField handleClose={handleClose} />
			}
			case 'social': {
				return (
					<AddSocialForm
						initial={inputObj}
						userId={userId}
						authHeader={authHeader}
						handleClose={handleClose}
					/>
				)
			}
			case 'delete': {
				return (
					<DeleteAccountForm
						handleClose={handleClose}
						userId={userId}
						authHeader={authHeader}
					/>
				)
			}
			default:
				return null
		}
	}

	return (
		<SetInputModalContext.Provider value={setInputObj}>
			<Outlet />
			<div
				ref={wrapperRef}
				className='scrollbar-hide hidden-opacity fixed left-0 top-0 flex h-dvh min-h-fit
					w-full items-center justify-center overflow-y-scroll'
			>
				<button
					type='button'
					onClick={handleClose}
					aria-label='Закрыть'
					className='absolute right-0 top-0 size-full bg-black/20'
				/>
				<div
					className='relative z-10 flex w-[calc(100%-16px)] flex-col rounded-xl bg-white
						px-2 py-4 2xs:w-[calc(100%-32px)] 2xs:p-4 lg:w-[480px]'
				>
					{renderElement()}
				</div>
			</div>
		</SetInputModalContext.Provider>
	)
}

export function useSetInputModalContext() {
	return useContext(SetInputModalContext)
}
