import { useEffect } from 'react'
import ContentWrapper from '../components/content-wrapper'
import CreateTaskForm from '../components/task/create-task-form'

export default function CreateOfferPage() {
	useEffect(() => {
		const pageIndicator = document.getElementById('page-indicator')

		if (pageIndicator) pageIndicator.textContent = 'Создать оффер'
	}, [])

	return (
		<ContentWrapper>
			<h1 className='mb-4 hidden text-2xl font-semibold text-primary-500 lg:block'>
				Создать оффер
			</h1>
			<CreateTaskForm />
		</ContentWrapper>
	)
}
