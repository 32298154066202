/* eslint-disable no-unused-vars */
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import { useNavigate } from 'react-router-dom'
import TaskCard from './task-card'
import { getChat, sendMessage } from './utils/chat-processing'
import PriceView from '../price-view'
import Bolt from '../icons/bolt'
import toggleModal from '../utils/toggle-modal'

export default function TasksList({
	className,
	items,
	user = null,
	authUser = {},
	isFavourite = false,
}) {
	const navigate = useNavigate()
	const authHeader = useAuthHeader()

	const isBusiness = authUser.type === 'business'
	const { id: authUserId } = authUser

	const favouritesArr = JSON.parse(
		localStorage.getItem('favourites') || '[]'
	)

	const handleClick = async event => {
		if (user) event.preventDefault()

		if (event.target.id === 'favourite-btn') {
			event.preventDefault()
			const { target } = event
			const { taskId } = target.dataset

			const taskIdNumber = Number(taskId)
			const index = favouritesArr.findIndex(
				item => item === taskIdNumber
			)

			if (index > -1) {
				target.classList.remove('in-favourite')
				favouritesArr.splice(index, 1)
				if (isFavourite) {
					document.querySelector(
						`a[id="${taskIdNumber}"]`
					).style.display = 'none'
				}
			} else {
				target.classList.add('in-favourite')
				favouritesArr.push(taskIdNumber)
			}

			localStorage.setItem(
				'favourites',
				`[${favouritesArr.join(',')}]`
			)
		}

		if (event.target.id === 'acceptTask-btn') {
			event.preventDefault()
			const { target } = event
			const { userId, taskId } = target.dataset

			const taskIdNumber = Number(taskId)
			const { shortDescription: description, price } = items.filter(
				item => item.id === taskIdNumber
			)[0].attributes

			const chatId = await getChat(
				authUserId,
				Number(userId),
				authHeader
			)

			if (chatId > -1) {
				const isMessageSended = await sendMessage(
					{
						senderId: authUserId,
						text: isBusiness
							? 'Хочу выдать задание!'
							: 'Хочу принять задание!',
						attachment: [
							{
								type: 'task',
								content: {
									description,
									price,
								},
							},
						],
						chat: chatId,
					},
					authHeader
				)

				if (isMessageSended) {
					navigate(`/deals/${chatId}`)
				}
			}
		}
	}

	const linkState = { prevPath: '/feed', notPath: true }

	const cardClass = isBusiness
		? 'w-full sm:w-[calc((100%-(3*8px))/3)]'
		: 'w-full sm:w-[calc((100%-(2*8px))/2)]'

	return (
		<div
			className={`-m-1 flex flex-row flex-wrap *:m-1 lg:w-fit ${className}`}
			id='tasks-list'
		>
			{items.map(item => (
				<TaskCard
					className={cardClass}
					content={item.attributes}
					linkState={linkState}
					id={item.id}
					isActive={favouritesArr.includes(item.id)}
					key={item.id}
					user={user}
					onClick={handleClick}
					isBusiness={isBusiness}
				/>
			))}
			{!isFavourite &&
			<button
				type='button'
				onClick={toggleModal}
				data-modal-id='subscribe-modal'
				className={`flex h-fit flex-col rounded-2xl bg-primary-500 p-4 font-semibold
					text-white *:pointer-events-none ${cardClass}`}
			>
				<span className='mb-3 text-left'>
					Для просмотра всех офферов подключите Flowork PRO
				</span>
				<div className='-m-1 flex w-full flex-row flex-wrap items-center justify-between'>
					<PriceView value={1490} isDark={false} className='m-1' />
					<div className='m-1 flex flex-row items-center rounded-full bg-white px-2.5 py-1.5'>
						<Bolt className='mr-1 size-5 fill-primary-500' />
						<span className='text-sm text-primary-500'>
							Подключить
						</span>
					</div>
				</div>
			</button>}
		</div>
	)
}
