import { forwardRef, useCallback, useState } from 'react'
import { Eye, EyeOpen } from '../../icons/password-eye'

const PasswordInput = forwardRef(
	({ className, isDark = false, ...props }, ref) => {
		const [isHidden, setIsHidden] = useState(true)

		const handleClick = useCallback(
			event => {
				event.preventDefault()
				setIsHidden(prev => !prev)
			},
			[setIsHidden]
		)

		const eyeStyle = isDark ? 'text-black' : 'text-white'

		return (
			<div className='relative'>
				<input
					type={isHidden ? 'password' : 'text'}
					{...props}
					ref={ref}
					className={`relative w-[calc(100%-20px-10px)] ${className}`}
				/>
				<button
					aria-label='Показать пароль'
					type='button'
					onClick={handleClick}
					className='absolute right-0 top-0'
				>
					{!isHidden ? (
						<Eye className={`size-5 ${eyeStyle}`} />
					) : (
						<EyeOpen className={`size-5 ${eyeStyle}`} />
					)}
				</button>
			</div>
		)
	}
)

export default PasswordInput
