export default function TextInputDisabled({ attributes, className }) {
	return (
		<div
			className={`flex flex-col rounded-xl border border-[#E2E2E2] px-4 py-2 text-left
				${className}`}
		>
			<span className='pointer-events-none text-xs text-black/60'>
				{attributes?.label}
			</span>
			<span className='pointer-events-none line-clamp-1 text-base text-black/60'>
				{attributes?.value}
			</span>
		</div>
	)
}
