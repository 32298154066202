import { Link } from 'react-router-dom'
import timeAgo from './time-ago'
import PriceView from '../price-view'
import Plus from '../icons/plus'
import Favourite from '../icons/favourite'

export default function TaskCard({
	content,
	id,
	className = '',
	user = { data: { attributes: null } },
	linkState,
	onClick,
	isBusiness = false,
	isActive,
}) {
	const userInfo = content.user?.data?.attributes || user

	const avatar =
		userInfo.avatar?.data?.attributes?.url || userInfo.avatar?.url

	return (
		<Link
			to={`/feed/${content.user?.data?.id || user.id}`}
			state={linkState}
			onClick={onClick}
			id={id}
			className={`relative flex flex-col justify-between rounded-2xl border
				border-primary-500/10 p-4 text-black ${className}`}
		>
			<div className='flex flex-row items-start'>
				<img
					src={
						avatar
							? `https://api.flowork.ru${avatar}?format=webp&w=120`
							: '/images/default-profile.webp'
					}
					alt='Аватар'
					className='size-10 shrink-0 rounded-full object-cover'
				/>
				<div className='ml-2 flex flex-col'>
					<span className='line-clamp-1 text-sm font-semibold'>
						{userInfo.business?.name || userInfo.profile.name}
					</span>
					<span className='text-sm text-black/60'>
						{timeAgo(content.createdAt)}
					</span>
				</div>
			</div>
			<span className='mt-3 text-base'>
				{content.shortDescription}
			</span>
			<div className='-m-1 mt-2 flex flex-row flex-wrap'>
				<PriceView value={content.price} className='m-1' />
				<button
					type='button'
					id='acceptTask-btn'
					data-task-id={id}
					data-user-id={content.user?.data?.id || user.id}
					className='m-1 flex flex-row items-center rounded-full bg-gray-200/50 px-2.5
						py-1.5 text-primary-500 *:pointer-events-none'
				>
					<Plus className='mr-1 size-5 fill-primary-500' />
					<span className='text-sm font-semibold'>
						{isBusiness ? 'Дать задание' : 'Принять задание'}
					</span>
				</button>
			</div>
			<button
				type='button'
				id='favourite-btn'
				data-task-id={id}
				className={`absolute right-4 top-4 *:pointer-events-none
					${isActive ? 'in-favourite' : ''}`}
				aria-label='Добавить в избранное'
			>
				<Favourite className='size-5 fill-white stroke-primary-500 stroke-1 transition-[fill]' />
			</button>
		</Link>
	)
}
