export function Eye(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M14 21.2041C21.1367 21.2041 25.9531 15.4473 25.9531 13.6631C25.9531 11.8877 21.1279 6.13086 14 6.13086C6.96875 6.13086 2.04688 11.8877 2.04688 13.6631C2.04688 15.4473 6.95996 21.2041 14 21.2041ZM14.0088 18.4619C11.3281 18.4619 9.19238 16.2734 9.18359 13.6719C9.1748 10.9824 11.3281 8.87305 14.0088 8.87305C16.6719 8.87305 18.8252 10.9912 18.8252 13.6719C18.8252 16.2734 16.6719 18.4619 14.0088 18.4619ZM14.0088 15.4561C14.9932 15.4561 15.8105 14.6475 15.8105 13.6719C15.8105 12.6875 14.9932 11.8701 14.0088 11.8701C13.0068 11.8701 12.1982 12.6875 12.1982 13.6719C12.1982 14.6475 13.0068 15.4561 14.0088 15.4561Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export function EyeOpen(props) {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M20.9346 21.4411C21.207 21.7135 21.6465 21.7311 21.9277 21.4411C22.2178 21.1422 22.2002 20.7204 21.9277 20.4479L7.0127 5.55043C6.74023 5.27797 6.29199 5.26918 6.01953 5.55043C5.75586 5.8141 5.75586 6.27113 6.01953 6.5348L20.9346 21.4411ZM21.7607 18.7516C24.3271 17.0289 25.9531 14.6998 25.9531 13.6627C25.9531 11.8873 21.1279 6.13051 14 6.13051C12.5059 6.13051 11.1084 6.38539 9.84277 6.80726L12.251 9.21547C12.7959 8.99574 13.3848 8.87269 14.0088 8.87269C16.6719 8.87269 18.8252 10.9909 18.8252 13.6715C18.8252 14.2868 18.6934 14.8844 18.4561 15.4205L21.7607 18.7516ZM14 21.2037C15.6084 21.2037 17.1025 20.9137 18.4209 20.4303L15.9951 18.0045C15.3975 18.3034 14.7207 18.4616 14.0088 18.4616C11.3281 18.4616 9.19238 16.2731 9.18359 13.6715C9.18359 12.942 9.3418 12.2565 9.63184 11.65L6.45898 8.45961C3.72559 10.2174 2.04688 12.6256 2.04688 13.6627C2.04688 15.4469 6.95996 21.2037 14 21.2037ZM16.8125 13.487C16.8125 11.9489 15.582 10.7096 14.0352 10.7096C13.9385 10.7096 13.8418 10.7184 13.7539 10.7272L16.7949 13.7594C16.8037 13.6803 16.8125 13.5836 16.8125 13.487ZM11.1787 13.5221C11.1787 15.0602 12.4443 16.2995 13.9736 16.2995C14.0791 16.2995 14.1758 16.2907 14.2725 16.2819L11.1963 13.2057C11.1875 13.3112 11.1787 13.4166 11.1787 13.5221Z'
				fill='currentColor'
			/>
		</svg>
	)
}
