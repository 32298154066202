export default function PolicyLabel({ className = '' }) {
	return (
		<div className={`text-center text-sm text-black/60 ${className}`}>
			<p>
				Нажимая на &quot;Зарегистироваться&quot;, Вы&nbsp;соглашаетесь
				с&nbsp;
				<a
					href='https://flowork.ru/privacy'
					target='_blank'
					rel='noopener noreferrer'
					className='decoration-skip-ink-none underline underline-offset-4'
				>
					Политикой&nbsp;конфиденциальности
				</a>
			</p>
		</div>
	)
}
