import { useRef, memo } from 'react'

const SelectInput = memo(
	({
		setValue = () => {},
		values = [],
		label,
		error,
		resetError = () => {},
		errorClass = 'input-error',
		className = 'border border-[#E2E2E2] text-black',
	}) => {
		const containerRef = useRef(null)

		const handleAccordionClick = event => {
			const accordion =
				containerRef.current?.querySelector('#accordion')

			if (accordion) {
				accordion.classList.toggle('accordion-open')
				event.target
					.querySelector('#chevron')
					?.classList?.toggle('chevron-rotate')
			}
		}

		const handleValueClick = event => {
			const { current } = containerRef
			resetError()
			if (current) {
				const valueSpan = current.querySelector('#select-value')
				const { value } = event.target.dataset

				if (valueSpan && value) {
					valueSpan.textContent = value
					valueSpan.classList.remove('opacity-60')
					setValue(value)
				}

				const btn = current.querySelector('button')
				if (btn) btn.click()
			}
		}

		return (
			<div
				ref={containerRef}
				className={`flex flex-col ${error ? errorClass : ''}`}
			>
				<button
					type='button'
					onClick={handleAccordionClick}
					id='label'
					className={`flex flex-col rounded-xl *:pointer-events-none ${className} px-4 py-2
						text-left transition-colors duration-300`}
				>
					<span className='text-xs text-inherit opacity-60 transition-opacity duration-300'>
						{label}
					</span>
					<div className='flex w-full flex-row items-center justify-between'>
						<span
							id='select-value'
							className='w-full text-inherit opacity-60'
						>
							Выбрать
						</span>
						<img
							src='/icons/chevron-left.svg'
							width={30}
							height={30}
							alt=''
							id='chevron'
							className='size-6 shrink-0 -rotate-90 transition-transform duration-300'
						/>
					</div>
				</button>
				<div
					id='accordion'
					style={{
						'--multiplier': values.length,
						'--p-y': '14px',
						'--line-h': '24px',
						'--border-between': '1px',
						'--margin': '4px 0px 0px 0px',
					}}
					className='flex h-0 flex-col overflow-hidden rounded-xl shadow
						transition-[height,margin] duration-300'
				>
					{values.map(item => (
						<button
							key={item}
							onClick={handleValueClick}
							type='button'
							data-value={item}
							className='border-b border-[#E2E2E2] px-4 py-3.5 text-left text-base
								last:border-none'
						>
							{item}
						</button>
					))}
				</div>
				<span
					className='flex h-0 items-end text-sm text-red-500 opacity-0 transition-opacity
						duration-300'
					style={{ transitionProperty: 'opacity, height' }}
					id='error-text'
				>
					{error?.message}
				</span>
			</div>
		)
	}
)

export default SelectInput
