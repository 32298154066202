import { useRef } from 'react'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import Spinner from '../icons/spinner'
import useLoader from '../utils/use-loader'

export default function AvatarInput({ image, revalidator }) {
	const inputRef = useRef(null)
	const { btnRef, toggleLoader } = useLoader()

	const authHeader = useAuthHeader()
	const { id: userId } = useAuthUser()

	const handleButtonClick = () => {
		if (inputRef.current) inputRef.current.click()
	}

	const handleChange = event => {
		toggleLoader(true)
		const formData = new FormData()
		const file = event.target.files[0]

		formData.append('files', file, file.name)
		formData.append('ref', 'plugin::users-permissions.user')
		formData.append('refId', userId)
		formData.append('field', 'avatar')

		fetch(`https://api.flowork.ru/api/upload`, {
			method: 'POST',
			headers: {
				Authorization: authHeader,
			},
			body: formData,
		})
			.then(() => {
				revalidator.revalidate()
				toggleLoader(false)
				// TODO: show a error if status !== 200
			})
			.catch(() => {})
	}

	return (
		<label
			className='flex flex-col rounded-xl border border-[#E2E2E2] px-4 py-2 text-left'
			htmlFor='avatar'
		>
			<span className='mb-2 text-xs text-black/60'>Аватар</span>
			<input
				type='file'
				multiple={false}
				accept='image/png, image/jpeg'
				name='avatar'
				id='avatar'
				ref={inputRef}
				onChange={handleChange}
				hidden
			/>
			<div className='flex flex-row items-center'>
				<img
					src={
						image
							? `https://api.flowork.ru${image.url}?format=webp&w=112&h=112`
							: '/images/default-profile.webp'
					}
					className='size-14 shrink-0 rounded-full object-cover'
					alt='Аватар'
					loading='lazy'
				/>
				<button
					aria-label='Заменить фото'
					type='button'
					onClick={handleButtonClick}
					ref={btnRef}
					className='ml-4 flex w-full flex-row items-center justify-center rounded-xl
						bg-primary-500 px-4 py-2 text-center text-base font-semibold
						text-white *:pointer-events-none'
				>
					<span>Заменить фото</span>
					<Spinner
						className='size-6 rounded-full fill-gray-400 text-white'
						style={{ display: 'none' }}
					/>
				</button>
			</div>
		</label>
	)
}
